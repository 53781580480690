import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
  Container,
  Box,
  TablePagination,
  IconButton,
  ThemeProvider,
} from "@mui/material";
import { format, parseISO } from "date-fns";
import axios from "axios";
import { createTheme } from "@mui/material/styles";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import UserDeletionConfirmation from "./userDeletionConfirmation";
import ChangeUserPassword from "./changeUserPassword";
import UserLockConfirmation from "./userLockConfirmation";
import EditUserDialog from "./editUserDialog";

const theme = createTheme();

interface UsersDirectoryProps {
  role: "admin" | "super-admin" | "user" | null;
  setRole: React.Dispatch<
    React.SetStateAction<"admin" | "super-admin" | "user" | null>
  >;
  isImposter: boolean;
  setIsImposter: React.Dispatch<React.SetStateAction<boolean>>;
}

interface User {
  id: number;
  email: string;
  passwordHash: string;
  verificationCode: string;
  codeExpires: Date;
  role: string;
  partnerId: string;
  isLocked: boolean;
  createdAt: string;
  data_source_id: number;
}

interface Partner {
  partner_name: string;
  crm_list_id: number;
}

const UsersDirectory: React.FC<UsersDirectoryProps> = ({
  role,
  setRole,
  isImposter,
  setIsImposter,
}) => {
  const navigate = useNavigate();
  const [orderBy, setOrderBy] = useState<keyof User>("createdAt");
  const [order, setOrder] = useState<"asc" | "desc">("desc");
  const [users, setUsers] = useState<User[]>([]);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [partners, setPartners] = useState<Record<string, string>>({});
  const [deleteUserId, setDeleteUserId] = useState<number | null>(null);
  const [isConfirmationOpen, setConfirmationOpen] = useState<boolean>(false);
  const [isChangePasswordDialogOpen, setChangePasswordDialogOpen] =
    useState<boolean>(false);
  const [selectedUserId, setSelectedUserId] = useState<number | null>(null);
  const [isLockConfirmationOpen, setLockConfirmationOpen] =
    useState<boolean>(false);
  const [lockUserId, setLockUserId] = useState<number | null>(null);
  const [lockUserStatus, setLockUserStatus] = useState<boolean>(false);
  const [isEditDialogOpen, setEditDialogOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [selectedPartner, setSelectedPartner] = useState<Partner | null>(null);

  useEffect(() => {
    const fetchUserRole = async () => {
      const resp = await axios.get(
        "https://backend.partner-bigthinkcapital.com/api/get-user-role",
        {
          withCredentials: true,
        }
      );
      setRole(resp.data);
    };
    const fetchUsers = async () => {
      try {
        const response = await axios.get<User[]>(
          "https://backend.partner-bigthinkcapital.com/api/get-users",
          { withCredentials: true }
        );
        setUsers(response.data);
        await fetchPartnerNames(response.data);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    const fetchPartnerNames = async (users: User[]) => {
      const partnerIds: string[] = [
        ...new Set(
          users
            .filter((user) => user.role === "user")
            .map((user) => user.partnerId)
        ),
      ];

      const partnerNames: Record<string, string> = {};
      for (const partnerId of partnerIds) {
        const name = await getPartnerName(partnerId);
        partnerNames[partnerId] = name;
      }
      setPartners(partnerNames);
    };
    fetchUserRole();
    fetchUsers();
    handleRequestSort("createdAt");
  }, []);

  const handleRequestSort = (property: keyof User) => {
    const isAscending = orderBy === property && order === "asc";
    setOrder(isAscending ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleLogout = async () => {
    try {
      const response = await axios.post<{ message: string }>(
        "https://backend.partner-bigthinkcapital.com/api/logout",
        {},
        { withCredentials: true }
      );
      if (response.data.message === "Logout successful") {
        navigate("/");
      } else {
        console.error("Logout failed:", response.data.message);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const getPartnerName = async (partnerId: string): Promise<string> => {
    try {
      const response = await axios.get<{ name: string }>(
        `https://backend.partner-bigthinkcapital.com/api/get-partner/${partnerId}`,
        { withCredentials: true }
      );
      return response.data.name;
    } catch (error) {
      console.error("Error fetching partner name:", error);
      return "";
    }
  };

  const handleDelete = (userId: number | null) => {
    setDeleteUserId(userId);
    setConfirmationOpen(true);
  };

  const handleConfirmDelete = async () => {
    if (deleteUserId) {
      try {
        await axios.delete(
          `https://backend.partner-bigthinkcapital.com/api/delete-user/${deleteUserId}`,
          { withCredentials: true }
        );
        setUsers(users.filter((user) => user.id !== deleteUserId));
        setConfirmationOpen(false);
      } catch (error) {
        console.error("Error deleting user:", error);
      }
    }
  };

  const handleCancelDelete = () => {
    setDeleteUserId(null);
    setConfirmationOpen(false);
  };

  const handleLockToggle = (userId: number, isLocked: boolean) => {
    setLockUserId(userId);
    setLockUserStatus(isLocked);
    setLockConfirmationOpen(true);
  };

  const handleConfirmLockToggle = async () => {
    if (lockUserId !== null) {
      try {
        const action = lockUserStatus ? "unlock" : "lock";
        await axios.post(
          `https://backend.partner-bigthinkcapital.com/api/toggle-lock-user/${lockUserId}`,
          { action },
          { withCredentials: true }
        );
        setUsers(
          users.map((user) =>
            user.id === lockUserId
              ? { ...user, isLocked: !lockUserStatus }
              : user
          )
        );
        setLockConfirmationOpen(false);
        setLockUserId(null);
      } catch (error) {
        console.error("Error locking/unlocking user:", error);
      }
    }
  };

  const handleCreateUser = () => {
    navigate("/create-user");
  };

  const handleLoginAsUser = async (userId: number | null) => {
    try {
      const response = await axios.post<{ message: string }>(
        "https://backend.partner-bigthinkcapital.com/api/login-as-user",
        { userId },
        { withCredentials: true }
      );
      if (response.data.message === "Login as user successful") {
        setIsImposter(true);
        navigate("/home");
      } else {
        console.error("Login failed:", response.data.message);
      }
    } catch (error) {
      console.error("Error logging in as user:", error);
    }
  };

  const handleOpenChangePasswordDialog = (userId: number | null) => {
    setSelectedUserId(userId);
    setChangePasswordDialogOpen(true);
  };

  const handleCloseChangePasswordDialog = () => {
    setSelectedUserId(null);
    setChangePasswordDialogOpen(false);
  };

  const handleChangePassword = async (newPassword: string) => {
    if (selectedUserId) {
      try {
        await axios.put(
          `https://backend.partner-bigthinkcapital.com/api/change-user-password`,
          { userId: selectedUserId, newPassword },
          { withCredentials: true }
        );
        handleCloseChangePasswordDialog();
      } catch (error) {
        console.error("Error changing password:", error);
      }
    }
  };

  const handleEditUser = async (user: any) => {
    setSelectedUser(user);
    const response = await axios.get(
      `https://backend.partner-bigthinkcapital.com/api/get-partner-by-user-id/${user.id}`,
      { withCredentials: true }
    );
    const partner = response.data.partner;
    // console.log("partner of selected user", partner);
    setSelectedPartner(partner);
    setEditDialogOpen(true);
  };

  const handleSaveUser = async (userData: any) => {
    // console.log(`selected user: ${selectedUser}, selected user id: ${selectedUser?.id}, userData: ${userData}`);
    try {
      await axios.put(
        `https://backend.partner-bigthinkcapital.com/api/update-user-info`,
        { userData: userData, userId: selectedUser?.id },
        {
          withCredentials: true,
        }
      );
      setUsers((prevUsers) =>
        prevUsers.map((user) =>
          user.id === selectedUser?.id ? { ...user, ...userData } : user
        )
      );
      // Update partners state if partner name was changed
      if (userData.partnerName && selectedUser) {
        setPartners((prevPartners) => ({
          ...prevPartners,
          [selectedUser.partnerId]: userData.partnerName,
        }));
      }
    } catch (error) {
      console.error("Error updating user:", error);
    }
  };

  const gradientStyle: React.CSSProperties = {
    background: "linear-gradient(90deg, rgb(24, 94, 157), rgb(45, 145, 195))",
  };

  const cellStyle: React.CSSProperties = {
    padding: "12px 16px",
  };

  const iconButtonStyle: React.CSSProperties = {
    padding: "8px",
  };

  return role === "admin" || role === "super-admin" ? (
    <Container>
      <Box position="absolute" top={10} left={10}>
        <img
          src="/btc_logo_transparent.png"
          alt="Logo"
          style={{ width: "10%", height: "5%" }}
        />
      </Box>
      <Box display="flex" justifyContent="flex-end" mt={5} mb={2}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddCircleOutlineIcon />}
          style={{ ...gradientStyle, marginRight: "10px" }}
          onClick={handleCreateUser}
        >
          Create User
        </Button>
        <Button
          className="hide-for-pdf"
          variant="contained"
          style={gradientStyle}
          onClick={handleLogout}
        >
          Logout
        </Button>
      </Box>
      <ThemeProvider theme={theme}>
        <Container>
          <Typography variant="h4" gutterBottom>
            Users
          </Typography>
          <TableContainer component={Paper} id="dashboard">
            <Table size="small">
              <TableHead>
                <TableRow style={gradientStyle}>
                  <TableCell
                    style={{
                      ...cellStyle,
                      color: "white",
                      width: "12%",
                      whiteSpace: "nowrap",
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <TableSortLabel
                        active={orderBy === "createdAt"}
                        direction={orderBy === "createdAt" ? order : "asc"}
                        onClick={() => handleRequestSort("createdAt")}
                      >
                        Created At
                      </TableSortLabel>
                    </div>
                  </TableCell>
                  <TableCell
                    style={{
                      ...cellStyle,
                      color: "white",
                      width: "10%",
                      whiteSpace: "nowrap",
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      Email
                    </div>
                  </TableCell>
                  <TableCell
                    style={{
                      ...cellStyle,
                      color: "white",
                      width: "15%",
                      whiteSpace: "nowrap",
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      Partner
                    </div>
                  </TableCell>
                  <TableCell
                    style={{
                      ...cellStyle,
                      color: "white",
                      width: "15%",
                      whiteSpace: "nowrap",
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      Login as User
                    </div>
                  </TableCell>
                  <TableCell
                    style={{
                      ...cellStyle,
                      color: "white",
                      width: "15%",
                      whiteSpace: "nowrap",
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      Lock/Unlock Account
                    </div>
                  </TableCell>
                  <TableCell
                    style={{
                      ...cellStyle,
                      color: "white",
                      width: "15%",
                      whiteSpace: "nowrap",
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      Change Password
                    </div>
                  </TableCell>
                  <TableCell
                    style={{
                      ...cellStyle,
                      color: "white",
                      width: "15%",
                      whiteSpace: "nowrap",
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      Edit
                    </div>
                  </TableCell>
                  {role === "super-admin" && (
                    <TableCell
                      style={{
                        ...cellStyle,
                        color: "white",
                        width: "15%",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        Delete Account
                      </div>
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {users &&
                  users
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map(
                      (user) =>
                        user.role === "user" && (
                          <TableRow key={user.id}>
                            <TableCell style={cellStyle}>
                              {format(parseISO(user.createdAt), "MM/dd/yyyy")}
                            </TableCell>
                            <TableCell style={cellStyle}>
                              {user.email}
                            </TableCell>
                            <TableCell style={cellStyle}>
                              {partners[user.partnerId]}
                            </TableCell>
                            <TableCell style={cellStyle}>
                              <IconButton
                                onClick={() => handleLoginAsUser(user.id)}
                                style={{ ...iconButtonStyle }}
                              >
                                <img
                                  src="/3652417.png"
                                  alt="Icon"
                                  style={{
                                    marginRight: "90%",
                                    width: "30%",
                                    height: "30%",
                                  }}
                                />
                              </IconButton>
                            </TableCell>
                            <TableCell style={cellStyle}>
                              <IconButton
                                onClick={() =>
                                  handleLockToggle(user.id, user.isLocked)
                                }
                                color="default"
                              >
                                {user.isLocked ? (
                                  <LockIcon />
                                ) : (
                                  <LockOpenIcon />
                                )}
                              </IconButton>
                            </TableCell>
                            <TableCell style={cellStyle}>
                              <IconButton
                                onClick={() =>
                                  handleOpenChangePasswordDialog(user.id)
                                }
                                color="default"
                              >
                                <VpnKeyIcon />
                              </IconButton>
                            </TableCell>
                            <TableCell>
                              <IconButton onClick={() => handleEditUser(user)}>
                                <EditIcon />
                              </IconButton>
                            </TableCell>
                            {role === "super-admin" && (
                              <TableCell style={cellStyle}>
                                <IconButton
                                  onClick={() => handleDelete(user.id)}
                                  color="error"
                                >
                                  <DeleteIcon style={{ color: "#B30000" }} />
                                </IconButton>
                              </TableCell>
                            )}
                          </TableRow>
                        )
                    )}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={users.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </Container>
      </ThemeProvider>
      <UserDeletionConfirmation
        open={isConfirmationOpen}
        onClose={handleCancelDelete}
        onConfirm={handleConfirmDelete}
      />
      <ChangeUserPassword
        open={isChangePasswordDialogOpen}
        onClose={handleCloseChangePasswordDialog}
        onSubmit={handleChangePassword}
      />
      <UserLockConfirmation
        open={isLockConfirmationOpen}
        onClose={() => setLockConfirmationOpen(false)}
        onConfirm={handleConfirmLockToggle}
        isLocked={lockUserStatus}
      />
      {selectedUser && (
        <EditUserDialog
          open={isEditDialogOpen}
          onClose={() => setEditDialogOpen(false)}
          onSave={handleSaveUser}
          user={selectedUser}
          partner={selectedPartner}
        />
      )}
    </Container>
  ) : (
    <Typography>Access Denied</Typography>
  );
};

export default UsersDirectory;
