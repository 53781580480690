import React, { useState, useEffect, useRef, CSSProperties } from "react";
import "../styles/index.css";
import { Button, Container, Box, Typography, IconButton } from "@mui/material";
import Dashboard from "./dashboard";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import * as XLSX from "xlsx";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Visualizations from "./visualizations";
import { Link } from "react-router-dom";
import UploadLeadForm from "./uploadLeadForm";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import SaveAltIcon from "@mui/icons-material/SaveAlt";

interface HomeProps {
  role: "admin" | "super-admin" | "user" | null;
  setRole: React.Dispatch<
    React.SetStateAction<"admin" | "super-admin" | "user" | null>
  >;
  isImposter: boolean;
}

interface Lead {
  id: string;
  full_name: string;
  created: string;
  business_name: string;
  email: string;
  status: string;
  stage: string;
  crm_id: string;
}

interface Partner {
  partner_name: string;
  crm_list_id: number;
}

const Home: React.FC<HomeProps> = ({ role, setRole, isImposter }) => {
  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const [orderBy, setOrderBy] = useState<keyof Lead>("created");
  const [leads, setLeads] = useState<Lead[] | null>([]);
  const [partnerName, setPartnerName] = useState<string>("");
  const [partner, setPartner] = useState<Partner | null>(null);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [direction, setDirection] = useState<"forward" | "backward">("forward");
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [filters, setFilters] = useState<{
    startDate: Date | null;
    endDate: Date | null;
  }>({
    startDate: null,
    endDate: null,
  });

  const navigate = useNavigate();
  const contentRef = useRef<HTMLDivElement>(null);

  // useEffect(() => {
  //   let roleResp: "admin" | "super-admin" | "user" | null = null;
  //   const fetchUserRoleAndGetLeads = async () => {
  //     if (!isImposter) {
  //       const response = await axios.get<
  //         "admin" | "super-admin" | "user" | null
  //       >("https://backend.partner-bigthinkcapital.com/api/get-user-role", {
  //         withCredentials: true,
  //       });
  //       roleResp = response.data;
  //       setRole(roleResp);
  //       if (roleResp === "admin" || roleResp === "super-admin") {
  //         navigate("/users-directory");
  //       } else {
  //         const leadsResponse = await axios.get<Lead[] | null>(
  //           "https://backend.partner-bigthinkcapital.com/api/get-leads",
  //           { withCredentials: true }
  //         );
  //         setLeads(leadsResponse?.data);
  //         await fetchPartnerName();
  //       }
  //     } else {
  //       roleResp = role;
  //       const leadsResponse = await axios.get<Lead[] | null>(
  //         "https://backend.partner-bigthinkcapital.com/api/get-leads",
  //         { withCredentials: true }
  //       );
  //       setLeads(leadsResponse?.data);
  //       await fetchPartnerName();
  //     }
  //   };
  //   const fetchPartnerName = async () => {
  //     const response = await axios.get(
  //       "https://backend.partner-bigthinkcapital.com/api/get-logged-in-user-partner",
  //       { withCredentials: true }
  //     );
  //     console.log("Partner response in home.tsx:", response.data);
  //     setPartnerName(response.data.partner.partner_name);
  //     setPartner(response.data.partner);
  //     setLoading(false);
  //   };
  //   fetchUserRoleAndGetLeads();
  // }, []);
  useEffect(() => {
    let isMounted = true;

    const fetchUserRoleAndGetLeads = async () => {
      let roleResp: "admin" | "super-admin" | "user" | null = null;
      setLoading(true);

      try {
        if (!isImposter) {
          const response = await axios.get<
            "admin" | "super-admin" | "user" | null
          >("https://backend.partner-bigthinkcapital.com/api/get-user-role", {
            withCredentials: true,
          });

          roleResp = response.data;
          if (isMounted) setRole(roleResp);

          if (roleResp === "admin" || roleResp === "super-admin") {
            navigate("/users-directory");
            return;
          }
        } else {
          roleResp = role;
        }

        // Fetch leads
        const leadsResponse = await axios.get<Lead[] | null>(
          "https://backend.partner-bigthinkcapital.com/api/get-leads",
          {
            withCredentials: true,
          }
        );

        if (isMounted && leadsResponse?.data) {
          const sortedLeads = leadsResponse.data.sort((a, b) => {
            const dateA = new Date(a.created).getTime();
            const dateB = new Date(b.created).getTime();
            return dateB - dateA; // Newest first
          });
          setLeads(sortedLeads);
        }

        await fetchPartnerName();
      } catch (error) {
        console.error(
          "Error fetching user role, leads, or partner name:",
          error
        );
      } finally {
        if (isMounted) setLoading(false);
      }
    };

    const fetchPartnerName = async () => {
      try {
        const response = await axios.get(
          "https://backend.partner-bigthinkcapital.com/api/get-logged-in-user-partner",
          {
            withCredentials: true,
          }
        );

        if (isMounted) {
          setPartnerName(response.data.partner.partner_name);
          setPartner(response.data.partner);
        }
      } catch (error) {
        console.error("Error fetching partner name:", error);
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    fetchUserRoleAndGetLeads();
    return () => {
      isMounted = false;
    };
  }, []);

  const handleRequestSort = (property: keyof Lead) => {
    if (leads) {
      const isAsc = orderBy === property && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(property);
      const sortedData = [...leads].sort((a, b) => {
        if (property === "created") {
          const dateA = new Date(a[property]);
          const dateB = new Date(b[property]);
          return isAsc
            ? dateA.getTime() - dateB.getTime()
            : dateB.getTime() - dateA.getTime();
        } else {
          if (a[property] < b[property]) {
            return isAsc ? -1 : 1;
          } else if (a[property] > b[property]) {
            return isAsc ? 1 : -1;
          } else {
            return 0;
          }
        }
      });
      setLeads(sortedData);
    }
  };

  const handleDateChange = (
    name: "startDate" | "endDate",
    date: Date | null
  ) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: date,
    }));
  };

  const nextPage = () => {
    if (currentPage < pages.length - 1) {
      setDirection("forward");
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 0) {
      setDirection("backward");
      setCurrentPage(currentPage - 1);
    }
  };

  const handleLogout = async () => {
    await axios
      .post<{ message: string }>(
        "https://backend.partner-bigthinkcapital.com/api/logout",
        {},
        { withCredentials: true }
      )
      .then((response) => {
        const data = response.data.message;
        if (data === "Logout successful") {
          navigate("/");
        } else {
          console.error("Logout failed:", data);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  // Array of pages or components to be displayed
  const pages: React.ReactNode[] = [
    <Dashboard
      partner={partner}
      leads={leads}
      setLeads={setLeads}
      loading={loading}
      filters={filters}
      handleDateChange={handleDateChange}
      order={order}
      orderBy={orderBy}
      handleRequestSort={handleRequestSort}
    />,
    <Visualizations
      leads={leads}
      filters={filters}
      handleDateChange={handleDateChange}
    />,
  ];

  const linkStyle: React.CSSProperties = {
    color: "blue",
    textDecoration: "underline",
    cursor: "pointer",
  };

  const nextPageIndicatorContainerStyle: React.CSSProperties = {
    position: "fixed",
    right: 0,
    top: "50%",
    width: "40px",
    height: "40px",
    background: "#eeeeee",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    transition: "background-color 0.3s ease",
    zIndex: 1000,
    borderRadius: "50%", // Rounded corners
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Add a shadow for better visibility
  };

  const prevPageIndicatorContainerStyle: React.CSSProperties = {
    position: "fixed",
    left: 0,
    top: "50%",
    width: "40px",
    height: "40px",
    background: "#eeeeee",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    transition: "background-color 0.3s ease",
    zIndex: 1000,
    borderRadius: "50%", // Rounded corners
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Add a shadow for better visibility
  };

  const nextPageIndicatorIconStyle: React.CSSProperties = {
    fontSize: "24px",
    color: "#000000",
  };

  const prevPageIndicatorIconStyle: React.CSSProperties = {
    marginLeft: "25%",
    fontSize: "24px",
    color: "#000000",
  };

  const gradientStyle: React.CSSProperties = {
    background: "linear-gradient(90deg, rgb(24, 94, 157), rgb(45, 145, 195))",
  };

  const goBackToAdminDashboard = async () => {
    await axios.post(
      "https://backend.partner-bigthinkcapital.com/api/switch-back-to-admin",
      {},
      { withCredentials: true }
    );
    navigate("/users-directory");
  };

  return (
    <Container
      maxWidth="xl"
      sx={{ paddingLeft: { xs: 1, md: 2 }, paddingRight: { xs: 1, md: 2 } }}
    >
      <Box position="absolute" top="1rem" left="1rem">
        <img
          src="/btc_logo_transparent.png"
          alt="Logo"
          style={{ width: "10%", height: "auto" }}
        />
      </Box>
      <Box position="absolute" top="1rem" right="1rem">
        {isImposter ? (
          <Button
            variant="contained"
            color="primary"
            sx={{
              ...gradientStyle,
              marginRight: { xs: 1, md: 2 },
              color: "white", // Set text color explicitly
              backgroundColor:
                "linear-gradient(90deg, rgb(24, 94, 157), rgb(45, 145, 195))", // Ensure background color remains the same
              "&.Mui-disabled": {
                color: "white", // Keep the text color the same when disabled
                backgroundColor:
                  "linear-gradient(90deg, rgb(24, 94, 157), rgb(45, 145, 195))", // Keep the background color the same
              },
            }}
            onClick={goBackToAdminDashboard}
            disabled={loading}
            className={loading ? "no-pointer-events" : ""}
          >
            Admin Dashboard
          </Button>
        ) : (
          <Button
            variant="contained"
            color="primary"
            sx={{
              ...gradientStyle,
              marginRight: { xs: 1, md: 2 },
              color: "white", // Set text color explicitly
              backgroundColor:
                "linear-gradient(90deg, rgb(24, 94, 157), rgb(45, 145, 195))", // Ensure background color remains the same
              "&.Mui-disabled": {
                color: "white", // Keep the text color the same when disabled
                backgroundColor:
                  "linear-gradient(90deg, rgb(24, 94, 157), rgb(45, 145, 195))", // Keep the background color the same
              },
            }}
            onClick={() => navigate("/contact-us")}
            disabled={loading}
            className={loading ? "no-pointer-events" : ""}
          >
            Contact Us
          </Button>
        )}
        <Button
          variant="contained"
          sx={{
            ...gradientStyle,
            marginRight: { xs: 1, md: 2 },
            color: "white", // Set text color explicitly
            backgroundColor:
              "linear-gradient(90deg, rgb(24, 94, 157), rgb(45, 145, 195))", // Ensure background color remains the same
            "&.Mui-disabled": {
              color: "white", // Keep the text color the same when disabled
              backgroundColor:
                "linear-gradient(90deg, rgb(24, 94, 157), rgb(45, 145, 195))", // Keep the background color the same
            },
          }}
          onClick={handleLogout}
          disabled={loading}
          className={loading ? "no-pointer-events" : ""}
        >
          Logout
        </Button>
      </Box>
      <Box
        ref={contentRef}
        display="flex"
        flexDirection="column"
        alignItems="center"
        mt={{ xs: "1.5rem", md: "3rem" }}
      >
        {/* <Typography color="rgb(24, 94, 157)"  fontSize={{ xs: "2rem", md: "2.5rem" }}>
          {partnerName}
        </Typography> */}
        <Box
          display="flex"
          width="100%"
          flexDirection="column"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box
            sx={{
              marginTop: "2.5rem",
              width: { xs: "80%", sm: "80%", md: "80%", lg: "80%", xl: "100%" },
            }}
            id="dashboard"
          >
            <SwitchTransition mode="out-in">
              <CSSTransition
                key={currentPage}
                addEndListener={(node, done) =>
                  node.addEventListener("transitionend", done, false)
                }
                classNames={
                  direction === "forward" ? "carousel-reverse" : "carousel"
                }
              >
                {pages[currentPage]}
              </CSSTransition>
            </SwitchTransition>
          </Box>
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          width="100%"
          mt={{ xs: "2rem", md: "3.5rem" }}
          mb="1rem"
        >
          <a
            href="https://bigthinkcapital.com/privacy-policy/"
            style={linkStyle}
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </a>
          <span style={{ margin: "0 10px" }}>|</span>{" "}
          {/* Separator between the links */}
          <a
            href="https://bigthinkcapital.com/terms-of-use/"
            style={linkStyle}
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms of Use
          </a>
        </Box>
      </Box>
      {currentPage < pages.length - 1 && (
        <Box
          onClick={loading ? undefined : nextPage} // Disable onClick when loading
          sx={{
            ...nextPageIndicatorContainerStyle,
            marginRight: { xs: "1rem", md: "2rem" },
            background: "background.paper",
            cursor: loading ? "not-allowed" : "pointer", // Show not-allowed cursor when loading
            "&:hover": {
              background: loading ? "background.paper" : "#dddddd", // Prevent hover effect when loading
            },
            opacity: loading ? 1 : 1, // Keep opacity the same
            pointerEvents: loading ? "none" : "auto", // Disable interaction when loading
          }}
        >
          <ArrowForwardIosIcon sx={nextPageIndicatorIconStyle} />
        </Box>
      )}
      {currentPage > 0 && (
        <Box
          onClick={prevPage}
          sx={{
            ...prevPageIndicatorContainerStyle,
            marginLeft: { xs: "1rem", md: "2rem" },
            background: "background.paper",
            "&:hover": { background: "#dddddd" },
          }}
        >
          <ArrowBackIosIcon sx={prevPageIndicatorIconStyle} />
        </Box>
      )}
    </Container>
  );
};

export default Home;
