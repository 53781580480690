import React from "react";
import { Container, Typography } from "@mui/material";
import {
  BarChart,
  Bar,
  ResponsiveContainer,
  LabelList,
  Cell,
  Legend,
  LegendType, // Import LegendType
} from "recharts";
import { Status } from "../statusDefinitions";

export const statusCategories: Record<string, Status[]> = {
  "Total in Progress": [
    "Specialty Prospect ",
    "Specialty Submitted",
    "Specialty Offers In",
    "Specialty Contracts Out",
    "Specialty Contracts In",
    "Specialty Pending Additional Info",
    "Specialty Review",
    "Renewal - App Out",
    "Renewal - App In",
    "Renewal - Submission",
    "Renewal - Offer In",
    "Renewal - Reviewing Offer",
    "Renewal - Contracts Out",
    "Renewal - Contracts In",
    "App Out",
    "App Out - No Drip",
    "Viewed DocuSign",
    "Viewed DocuSign - Marketing",
    "Offers In",
    "Steal- Working",
    "Steal- Dead",
    "Docs Recieved",
    "Pending Additional INFO ",
    "App In - Collecting Docs",
    "Sent to Operations",
    "Resubmit to Lenders",
    "Submitted to Funders",
    "Merchant Reviewing Offers",
    "Offer Accepted - Collecting Closing Docs",
    "Ready to Order Contracts",
    "Contracts Out",
    "Contracts Signed - Final Stips",
  ],
};

interface Lead {
  id: string;
  full_name: string;
  created: string;
  business_name: string;
  email: string;
  status: string;
  stage: string;
  crm_id: string;
}

interface BarGraphProps {
  leads?: Lead[] | null;
}

const BarGraph: React.FC<BarGraphProps> = ({ leads = [] }) => {
  const fundedStatuses = statusCategories["Total in Progress"];
  const fundedLeads =
    leads?.filter((lead) => fundedStatuses.includes(lead.status as Status)) ?? [];

  const statusCounts = fundedLeads.reduce((acc, lead) => {
    acc[lead.status] = (acc[lead.status] ?? 0) + 1;
    return acc;
  }, {} as Record<string, number>);

  const totalFundedLeads = fundedLeads.length;

  const data = [
    {
      name: "Total in Progress",
      ...Object.fromEntries(
        Object.entries(statusCounts).map(([status, count]) => [
          status,
          (count / totalFundedLeads) * 100,
        ])
      ),
    },
  ];

  const colors = [
    "#C44D58", // Solid Red
    "#4E7AC7", // Solid Blue
    "#62B475", // Solid Green
    "#FFA65E", // Solid Orange
    "#A37774", // Solid Brown
    "#4695B9", // Solid Sky Blue
    "#A665A1", // Solid Purple
  ];

  // Sync the order of the legend items with the order of the bars
  const legendPayload = Object.keys(statusCounts).map((status, index) => ({
    value: status,
    type: 'rect' as LegendType, // Ensure type is LegendType
    color: colors[index % colors.length],
  }));

  // Function to calculate font size based on the width of the bar segment
  const calculateFontSize = (value: number, width: number) => {
    const baseSize = 12; // Base font size
    const calculatedSize = (width / value) * 8; // Dynamic calculation
    return Math.min(baseSize, calculatedSize); // Return the smaller value to ensure the font isn't too large
  };

  return (
    <Container>
      <Typography variant="h5" align="left">
        By In - Progress Status
      </Typography>
      <ResponsiveContainer
  width="100%"
  height={380}
  minWidth={100} // Minimum width for small screens
>
        <BarChart data={data} layout="horizontal" >
        <Legend
  layout="vertical"
  align="right"
  verticalAlign="middle"
  wrapperStyle={{
    // paddingLeft: "20px",
    fontSize: "10px", // Default font size for the legend
  }}
  payload={legendPayload}
/>
          {Object.keys(statusCounts).map((status, index) => (
            <Bar
              key={status}
              dataKey={status}
              stackId="a"
              fill={colors[index % colors.length]}
              isAnimationActive={false} // Disable animation to avoid gray background on hover
            >
              <LabelList
                dataKey={status}
                position="inside"
                content={({ x, y, width, height, value }: any) => {
                  if (value >= 4) { // Only render label if it takes up 10% or more of the bar height
                    const fontSize = calculateFontSize(value, width);
                    const label = `${status} - ${value.toFixed(0)}%`;
                    const textWidth = fontSize * label.length * 0.6;
                    if (textWidth < width) { // Only render if the text fits within the bar segment
                      return (
                        <text
                          x={x + width / 2} // Center the text horizontally in the bar segment
                          y={y + height / 2} // Center the text vertically in the bar segment
                          fill="white"
                          textAnchor="middle"
                          dominantBaseline="middle"
                          style={{ fontSize: `${fontSize}px`, fontWeight: 'bold' }}
                        >
                          {`${status} - ${value.toFixed(0)}%`}
                        </text>
                      );
                    }              
                  }
                  return null;
                }}
              />
              {data.map((_, i) => (
                <Cell
                  key={`cell-${index}-${i}`}
                  fill={colors[index % colors.length]}
                  style={{
                    transition: "filter 0.3s ease-in-out",
                  }}
                />
              ))}
            </Bar>
          ))}
        </BarChart>
      </ResponsiveContainer>
    </Container>
  );
};

export default BarGraph;