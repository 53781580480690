import React, { useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import {
  ButtonGroup,
  Button,
  IconButton,
  Box,
  Typography,
  Collapse,
} from "@mui/material";
import {
  format,
  parseISO,
  startOfWeek,
  startOfMonth,
  eachMonthOfInterval,
  isValid,
} from "date-fns";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

interface Lead {
  id: string;
  created: string;
  full_name: string;
  business_name: string;
  email: string;
  status: string;
  stage: string;
  crm_id: string;
}

interface LineGraphProps {
  leads?: Lead[] | null;
}

const groupByDay = (data: Lead[] | null) => {
  const groupedData = data?.reduce<Record<string, number>>((acc, curr) => {
    const date = format(parseISO(curr.created), "yyyy-MM-dd");
    if (!acc[date]) acc[date] = 0;
    acc[date]++;
    return acc;
  }, {}) as Record<string, number>;
  return Object.entries(groupedData)
    .map(([key, value]) => ({
      name: key,
      "Leads Created": value,
    }))
    .sort((a, b) => new Date(a.name).getTime() - new Date(b.name).getTime()); // Ensure proper sorting
};

const groupByWeek = (data: Lead[] | null) => {
  const groupedData = data?.reduce<Record<string, number>>((acc, curr) => {
    const date = format(startOfWeek(parseISO(curr.created)), "yyyy-MM-dd");
    if (!acc[date]) acc[date] = 0;
    acc[date]++;
    return acc;
  }, {}) as Record<string, number>;

  return Object.entries(groupedData)
    .map(([key, value]) => ({
      name: key,
      "Leads Created": value,
    }))
    .sort((a, b) => new Date(a.name).getTime() - new Date(b.name).getTime()); // Ensure proper sorting
};

const groupByMonth = (data: Lead[] | null) => {
  const groupedData = data?.reduce<Record<string, number>>((acc, curr) => {
    const date = format(startOfMonth(parseISO(curr.created)), "yyyy-MM");
    if (!acc[date]) acc[date] = 0;
    acc[date]++;
    return acc;
  }, {}) as Record<string, number>;

  const sortedData = Object.entries(groupedData)
    .map(([key, value]) => ({
      name: key,
      "Leads Created": value,
    }))
    .sort((a, b) => new Date(a.name).getTime() - new Date(b.name).getTime()); // Ensure proper sorting

  return fillMissingMonths(sortedData);
};

const fillMissingMonths = (
  data: { name: string; "Leads Created": number }[]
) => {
  if (data.length === 0) return data;

  const startDate = parseISO(data[0].name + "-01");
  const endDate = parseISO(data[data.length - 1].name + "-01");

  if (!isValid(startDate) || !isValid(endDate) || startDate > endDate) {
    return data;
  }

  const allMonths = eachMonthOfInterval({ start: startDate, end: endDate });

  const filledData = allMonths.map((date) => {
    const formattedDate = format(date, "yyyy-MM");
    const existingData = data.find((d) => d.name === formattedDate);
    return existingData
      ? existingData
      : { name: formattedDate, "Leads Created": 0 };
  });

  return filledData;
};

const LineGraph: React.FC<LineGraphProps> = ({ leads = [] }) => {
  const [granularity, setGranularity] = useState<"day" | "week" | "month">(
    "day"
  );
  const [collapsed, setCollapsed] = useState(false);

  const getData = () => {
    switch (granularity) {
      case "day":
        return groupByDay(leads);
      case "week":
        return groupByWeek(leads);
      case "month":
        return groupByMonth(leads);
      default:
        return [];
    }
  };

  const data = getData();

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  const gradientStyle = {
    background: "linear-gradient(90deg, rgb(24, 94, 157), rgb(45, 145, 195))",
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          borderBottom: "1px solid blue",
          borderWidth: "1px",
          borderStyle: "solid",
          width: '100%',
          borderRadius: "4px",
          ...gradientStyle,
        }}
      >
        <IconButton
          onClick={toggleCollapse}
          sx={{ border: "1px solid blue", borderRadius: "2px", color: "white" }}
        >
          {collapsed ? <ExpandMoreIcon /> : <ExpandLessIcon />}
        </IconButton>
        <Typography sx={{ color: "white", marginRight: 'auto', marginLeft: "2%"}}>
          Leads By Date
        </Typography>
      </Box>
      <Collapse in={!collapsed}>
        <Box mb={2} sx={{ marginTop: '2%', display: 'flex', justifyContent: 'left', alignItems: 'left' }}>
          <ResponsiveContainer width="95%" height={300}>
            <LineChart data={data}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Line type="monotone" dataKey="Leads Created" stroke="#8884d8" />
            </LineChart>
          </ResponsiveContainer>
        </Box>
        <ButtonGroup
          variant="outlined"
          aria-label="outlined primary button group"
        >
          <Button
            onClick={() => setGranularity("day")}
            variant={granularity === "day" ? "contained" : "outlined"}
          >
            Day
          </Button>
          <Button
            onClick={() => setGranularity("week")}
            variant={granularity === "week" ? "contained" : "outlined"}
          >
            Week
          </Button>
          <Button
            onClick={() => setGranularity("month")}
            variant={granularity === "month" ? "contained" : "outlined"}
          >
            Month
          </Button>
        </ButtonGroup>
      </Collapse>
    </Box>
  );
};

export default LineGraph;