import React, { useState, useEffect, FormEvent } from "react";
import {
  Button,
  TextField,
  Container,
  Link,
  Typography,
  Box,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "axios";

interface LoginFormProps {
  setIsLoggedIn: React.Dispatch<React.SetStateAction<boolean>>;
  setIsImposter: React.Dispatch<React.SetStateAction<boolean>>;
}

interface ValidationError {
  email?: string;
  password?: string;
}

const LoginForm: React.FC<LoginFormProps> = ({
  setIsLoggedIn,
  setIsImposter,
}) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [errors, setErrors] = useState<ValidationError>({});
  const [loginError, setLoginError] = useState<string>(""); // State for login error message

  useEffect(() => {
    setIsLoggedIn(false);
    setIsImposter(false);
  }, []);

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    let validationErrors: ValidationError = {};

    if (!email) {
      validationErrors.email = "Email is required";
    }

    if (!password) {
      validationErrors.password = "Password is required";
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      try {
        await axios.post(
          "https://backend.partner-bigthinkcapital.com/api/login",
          // "http://backend-container:5000/api/login",
          // 'https://backend.partner-bigthinkcapital.com/api/login',
          // "https://backend.partner-bigthinkcapital.com/api/login",
          { email, password },
          { withCredentials: true }
        );
        // props.userRole === "user" ? navigate('/home') : navigate('/users-directory');
        setIsLoggedIn(true);
        navigate("/home");
      } catch (error: any) {
        if (error.response) {
          if (error.response.status === 401) {
            setLoginError("Invalid email or password. Please try again.");
          } else if (error.response.status === 403) {
            setLoginError("Your account is locked. Please contact support.");
          } else {
            setLoginError("Server error. Please try again later.");
          }
        } else if (error.request) {
          console.error("Request error:", error.request);
          setLoginError(
            "Network error. Please check your internet connection."
          );
        } else {
          console.error("Error:", error.message);
          setLoginError("An unexpected error occurred. Please try again.");
        }
      }
    }
  };

  const gradientStyle: React.CSSProperties = {
    background: "linear-gradient(90deg, rgb(24, 94, 157), rgb(45, 145, 195))",
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img
          src="/btc_logo_transparent.png"
          alt="Logo"
          style={{ marginBottom: "2%", width: "70%", height: "70%" }}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box component="form" onSubmit={handleSubmit} mt={2}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              error={!!errors.email}
              helperText={errors.email}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              error={!!errors.password}
              helperText={errors.password}
            />
            <Typography>
              <Link
                href="#"
                onClick={(event) => {
                  event.preventDefault();
                  navigate("/code-verification");
                }}
              >
                Forgot Password?
              </Link>
            </Typography>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              style={gradientStyle}
              sx={{ mt: 3, mb: 2 }}
            >
              Log In
            </Button>
            {loginError && (
              <Typography
                color="#B30000"
                variant="body2"
                align="center"
                sx={{ mt: 1 }}
              >
                {loginError}
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default LoginForm;
