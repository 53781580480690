import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  Typography,
  Box,
  Grid,
  Fade,
  Slide,
} from "@mui/material";

interface UploadLeadFormProps {
  open: boolean;
  onClose: () => void;
  onConfirm: (formData: {
    firstName: string;
    lastName: string;
    businessName: string;
    email: string;
  }) => void;
  loading: boolean;
  progress: number;
  message: string;
}

const gradientStyle = {
  background: "linear-gradient(90deg, rgb(24, 94, 157), rgb(45, 145, 195))",
};

const UploadLeadForm: React.FC<UploadLeadFormProps> = ({
  open,
  onClose,
  onConfirm,
  loading,
  progress,
  message,
}) => {
  const initialFormData = {
    firstName: "",
    lastName: "",
    businessName: "",
    email: "",
  };

  const [formData, setFormData] = useState(initialFormData);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    const { firstName, lastName, businessName, email } = formData;

    // Validation logic
    if (!firstName || !lastName || !businessName || !email) {
      return; // Prevent submission if any field is empty
    }
    onConfirm(formData);
    setFormData(initialFormData); // Clear the form after submission
  };

  const handleClose = () => {
    setFormData(initialFormData); // Clear the form when closing
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="lg"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: 3,
          backgroundColor: "#fff",
          boxShadow: "0px 10px 30px rgba(0, 0, 0, 0.1)",
        },
      }}
      TransitionComponent={Fade}
      TransitionProps={{ timeout: 500 }}
    >
      <DialogTitle
        sx={{
          textAlign: "center",
          fontWeight: "bold",
          paddingBottom: 2,
          paddingTop: 3,
          fontSize: 22,
          color: "#333",
        }}
      >
        Upload New Lead
      </DialogTitle>
      <DialogContent
        sx={{
          padding: "0 24px 24px",
        }}
      >
        {loading ? (
          <Fade in={loading}>
            <Box sx={{ textAlign: "center", marginTop: 2 }}>
              <Box className="continuous-progress-bar"></Box>
              {message && (
                <Typography align="center" mt={2} sx={{ color: "#333" }}>
                  {message}
                </Typography>
              )}
            </Box>
          </Fade>
        ) : (
          <Slide direction="up" in={!loading} mountOnEnter unmountOnExit>
            <Grid container spacing={2}>
              <Grid item xs={3}> 
                <TextField
                required
                  label="First Name"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleInputChange}
                  fullWidth
                  variant="outlined"
                  margin="dense"
                  InputLabelProps={{ shrink: true }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: 4,
                      backgroundColor: "#f9f9f9",
                    },
                    "& .MuiInputLabel-root": {
                      color: "#888",
                    },
                    "& .MuiInputBase-input": {
                      color: "#333",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                required
                  label="Last Name"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleInputChange}
                  fullWidth
                  variant="outlined"
                  margin="dense"
                  InputLabelProps={{ shrink: true }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: 4,
                      backgroundColor: "#f9f9f9",
                    },
                    "& .MuiInputLabel-root": {
                      color: "#888",
                    },
                    "& .MuiInputBase-input": {
                      color: "#333",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                required
                  label="Business Name"
                  name="businessName"
                  value={formData.businessName}
                  onChange={handleInputChange}
                  fullWidth
                  variant="outlined"
                  margin="dense"
                  InputLabelProps={{ shrink: true }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: 4,
                      backgroundColor: "#f9f9f9",
                    },
                    "& .MuiInputLabel-root": {
                      color: "#888",
                    },
                    "& .MuiInputBase-input": {
                      color: "#333",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                required
                  label="Email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  fullWidth
                  variant="outlined"
                  margin="dense"
                  InputLabelProps={{ shrink: true }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: 4,
                      backgroundColor: "#f9f9f9",
                    },
                    "& .MuiInputLabel-root": {
                      color: "#888",
                    },
                    "& .MuiInputBase-input": {
                      color: "#333",
                    },
                  }}
                />
              </Grid>
            </Grid>
          </Slide>
        )}
      </DialogContent>
      {!loading && (
        <DialogActions
          sx={{
            justifyContent: "space-between",
            paddingBottom: 2,
            paddingTop: 2,
            backgroundColor: "#fafafa",
            borderTop: "1px solid #e0e0e0",
            borderRadius: "0 0 3px 3px",
          }}
        >
          <Button
            onClick={handleClose}
            variant="outlined"
            sx={{
              marginLeft: 2,
              borderRadius: 20,
              color: gradientStyle.background, // Text color is set to match the gradient
              borderColor: gradientStyle.background, // Border color is set to the gradient
              backgroundColor: "white", // Background color is white
              "&:hover": {
                borderColor: gradientStyle.background, // Border color on hover remains the gradient
                backgroundColor: "white", // Background color on hover remains white
              },
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            variant="contained"
            sx={{
              marginRight: 2,
              borderRadius: 20,
              color: "white",
              backgroundColor: "primary.main", // Set the background color to the primary color
              "&:hover": {
                backgroundColor: "primary.dark", // Darker shade on hover
              },
            }}
          >
            Confirm
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default UploadLeadForm;