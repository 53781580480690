export type Status = 
  | 'Funded - DNC'
  | 'Funded - Not Interested'
  | 'Paid in Full'
  | 'Clawback'
  | 'Defaulted'
  | 'In Collections'
  | 'In Legal'
  | 'Fraud '
  | 'Funders Declined'
  | 'Funders Decline - Killed In Final'
  | 'Merchant Declined'
  | 'Do Not Contact'
  | 'Nurture'
  | 'False Information'
  | 'Not Interested - No Texting'
  | 'Customer is a Startup'
  | 'DNQ - Good Credit'
  | 'Extra Payout'
  | 'Already Funded by Competitor'
  | 'DNQ - Personal Banks'
  | 'DNQ - Low Rev'
  | 'Not Ready - Future'
  | 'Not Responsive'
  | 'Dead'
  | 'Does Not Qualify'
  | 'Ready to Order Contracts'
  | 'Contracts Out'
  | 'Contracts Signed - Final Stips'
  | 'Funded - In Repayment'
  | 'Specialty Funded'
  | 'Funded - RC Program'
  | 'Funded - Renewal Declined'
  | 'Hot Lead'
  | 'Cold Lead'
  | 'Pending Discovery Call'
  | 'Call Back'
  | 'Duplicate'
  | 'App Out - No Drip'
  | 'Viewed DocuSign'
  | 'Viewed DocuSign - Marketing'
  | 'Failed Attempt'
  | 'Steal- Working'
  | 'Steal- Dead'
  | 'WBL Loan'
  | 'Docs Recieved'
  | 'Pending Additional INFO '
  | 'App In - Collecting Docs'
  | 'Sent to Operations'
  | 'Resubmit to Lenders'
  | 'Submitted to Funders'
  | 'Offers In'
  | 'Merchant Reviewing Offers'
  | 'Offer Accepted - Collecting Closing Docs'
  | 'Specialty Prospect '
  | 'Specialty Funders Declined'
  | 'Specialty Declined'
  | 'Specialty Submitted'
  | 'Specialty Offers In'
  | 'Specialty Contracts Out'
  | 'Specialty Contracts In'
  | 'Specialty Merchant Declined'
  | 'Specialty Pending Additional Info'
  | 'Specialty Review'
  | 'Renewal - Eligible'
  | 'Renewal - Hot Lead'
  | 'Renewal - Call Back'
  | 'Renewal - Attempt 1'
  | 'Renewal - Attempt 2'
  | 'Renewal - Attempt 3'
  | 'Renewal - Attempt 4'
  | 'Renewal - Attempt 5'
  | 'Renewal - App Out'
  | 'Renewal - App In'
  | 'Sent to Renewal Ops'
  | 'Renewal - Submission'
  | 'Renewal - Offer In'
  | 'Renewal - Reviewing Offer'
  | 'Renewal - Contracts Out'
  | 'Renewal - Contracts In'
  | 'Renewal - Merchant Decline'
  | 'Renewal - Funder Declined'
  | 'Renewal - Not Interested'
  | 'Renewals - DNQ (Check Back Later)'
  |'Contacting'
  |'App Out'
  |'App in Collecting Docs'
  |'Docs Received'
  |'Submitted to Funders'
  |'Offers In'
  |'Merchant Reviewing Offers'
  |'TIB Less Than 4 Months'
  |'DNQ'
  |'No Business Bank Account'
  |'Monthly Rev Less Than $5k'
  |'Not Ready - Future'
  |'Funded in Repayment'
  |'Funders Declined'
  |'Merchant Declined'
  |'Defaulted Merchant '
  |'Do Not Contact'
  
export function getStatusDefinition(status: Status): string {
  switch (status) {
    case 'Funded - DNC':
    case 'Funded - Not Interested':
    case 'Paid in Full':
        return 'Congratulations You have been Funded!';
    case 'Clawback':
    case 'Defaulted':
    case 'In Collections':
    case 'In Legal':
      return 'Defaulted';
    case 'Fraud ':
      return 'Fraud';
    case 'Funders Declined':
    case 'Funders Decline - Killed In Final':
      return 'Lenders Declined';
    case 'Merchant Declined':
      return 'No longer interested in moving forward.';
    case 'Do Not Contact':
      return 'Do Not Contact';
    case 'Nurture':
        return 'Nurture';
    case 'False Information':
      return 'False Information';
    case 'Not Interested - No Texting':
    case 'Not Responsive':
      return 'Not Interested';
    case 'Customer is a Startup':
    case 'DNQ - Good Credit':
    case 'DNQ - Personal Banks':
    case 'DNQ - Low Rev':
    case 'Does Not Qualify':
      return 'Does not qualify';
    case 'Extra Payout':
        return 'Extra Payout';
    case 'Ready to Order Contracts':
        return 'Ready to Order Contracts';
    case 'Duplicate':
        return 'Duplicate';
    case 'WBL Loan':
        return 'WBL Loan';
    case 'Failed Attempt':
        return 'Failed Attempt';
    case 'Specialty Contracts In':
        return 'Contracts signed';
    case 'Already Funded by Competitor':
      return 'No longer interested in moving forward';
    case 'Not Ready - Future':
      return 'Not Ready - Future';
    case 'Dead':
      return 'Does not qualify';
    case 'Contracts Out':
      return 'Contracts sent to you';
    case 'Contracts Signed - Final Stips':
      return 'Contracts signed';
    case 'Funded - In Repayment':
    case 'Specialty Funded':
    case 'Funded - RC Program':
    case 'Funded - Renewal Declined':
      return 'Congratulations You have been Funded!';
    case 'Hot Lead':
    case 'Cold Lead':
      return 'Our Team is processing your application';
    case 'Pending Discovery Call':
      return 'Please contact us to go over your business';
    case 'Call Back':
      return 'We tried to reach out and have not heard back';
    case 'App Out - No Drip':
    case 'Viewed DocuSign':
    case 'Viewed DocuSign - Marketing':
      return 'We are waiting on an updated application';
    case 'Steal- Working':
    case 'Steal- Dead':
      return 'Offer in. Please contact us if you would like to move forward';
    case 'Docs Recieved':
      return 'We have received the requested documentation and are awaiting the requested application';
    case 'Pending Additional INFO ':
      return 'We are pending additional requested information from the merchant in order to continue the process';
    case 'App In - Collecting Docs':
      return 'We have received the application and are awaiting the requested documentation';
    case 'Sent to Operations':
      return 'Sent to Operations';
    case 'Resubmit to Lenders':
      return 'Sent to lenders that were not previously sent to';
    case 'Submitted to Funders':
      return 'File sent to our lender network';
    case 'Offers In':
      return 'Offers In';
    case 'Merchant Reviewing Offers':
      return 'Offer Presented';
    case 'Offer Accepted - Collecting Closing Docs':
      return 'Waiting on documents to order contracts';
    case 'Specialty Prospect ':
      return 'Specialty team is reviewing file';
    case 'Specialty Funders Declined':
      return 'Specialty Lenders Declined';
    case 'Specialty Declined':
      return 'Declined';
    case 'Specialty Submitted':
      return 'File sent to Lenders';
    case 'Specialty Offers In':
      return 'Offer in';
    case 'Specialty Contracts Out':
      return 'Contracts sent';
    case 'Specialty Merchant Declined':
      return 'Borrower no longer wants to move forward';
    case 'Specialty Pending Additional Info':
      return "Need additional information";
    case 'Specialty Review':
      return 'Team is reviewing your file';
    case 'Renewal - Eligible':
        return 'You are eligible for a renewal';
    case 'Renewal - Hot Lead':
      return 'You are eligible for a renewal';
    case 'Renewal - Call Back':
    case 'Renewal - Attempt 1':
    case 'Renewal - Attempt 2':
    case 'Renewal - Attempt 3':
    case 'Renewal - Attempt 4':
    case 'Renewal - Attempt 5':
      return 'We tried to reach out and have not heard back';
    case 'Renewal - App Out':
      return 'We are waiting on an updated application';
    case 'Renewal - App In':
      return 'App completed waiting on additional documents';
    case 'Sent to Renewal Ops':
      return 'Sent to Renewal Ops';
    case 'Renewal - Submission':
      return 'File sent to Lenders';
    case 'Renewal - Offer In':
      return 'Offer in';
    case 'Renewal - Reviewing Offer':
      return 'Offer Presented';
    case 'Renewal - Contracts Out':
      return 'Contracts out';
    case 'Renewal - Contracts In':
      return 'Contracts in';
    case 'Renewal - Merchant Decline':
      return 'Borrower no longer wants to move forward';
    case 'Renewal - Funder Declined':
      return 'Lenders declined';
    case 'Renewal - Not Interested':
      return 'Not interested in moving forward';
    case 'Renewals - DNQ (Check Back Later)':
      return 'Does not qualify';
    case "Contacting":
    return "We are contacting the merchant";
  case "App Out":
    return "We have made contact with the merchant and sent out the requested application to be completed";
  case "App in Collecting Docs":
    return "We have received the application and awaiting requested documentation";
  case "Docs Received":
    return "We have received the requested documentation and awaiting requested application";
  case "Submitted to Funders":
    return "We have submitted the file to our network and awaiting all responses";
  case "Offers In":
    return "There are offers in for the merchant that we have not yet discussed with them";
  case "Merchant Reviewing Offers":
    return "We have went over the offers with the merchant and they are reviewing";
  case "TIB Less Than 4 Months":
    return "Business is a start up";
  case "DNQ":
    return "Merchant has too many negative days in the bank account to qualify for financing";
  case "No Business Bank Account":
    return "Merchant does not have a business bank account, we cannot fund into a non-business bank account";
  case "Monthly Rev Less Than $5k":
    return "Merchant does less than 5k a month in a business bank account";
  case "Not Ready - Future":
    return "Merchant indicated that they are not ready for funding at this time but would reconsider in the future";
  case "Funded in Repayment":
    return "Funded";
  case "Funders Declined":
    return "Merchant has not met any of our lenders guidelines";
  case "Merchant Declined":
    return "We had reviewed the offers with the merchant and they had declined the offers available";
  case "Defaulted Merchant ":
    return "Merchant had defaulted (stopped payments, paused payments, adjusted payments, etc.) on a loan in the past and does not qualify for additional financing";
  case "Do Not Contact":
    return "Merchant had asked us not to contact them and to be taken off our contact list";
  default:
    return 'Definition not available';
  }
}
