import React, { useState, useEffect, ChangeEvent } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";

interface EditUserDialogProps {
  open: boolean;
  onClose: () => void;
  onSave: (userData: {
    email: string | undefined;
    partnerName: string | undefined;
    listId: number | undefined;
    dataSourceId: number | undefined;
  }) => void;
  user: User;
  partner: Partner | null;
}

interface User {
  id: number;
  email: string;
  passwordHash: string;
  verificationCode: string;
  codeExpires: Date;
  role: string;
  partnerId: string;
  isLocked: boolean;
  createdAt: string;
  data_source_id: number;
}

interface Partner {
  partner_name: string;
  crm_list_id: number;
}

const EditUserDialog: React.FC<EditUserDialogProps> = ({
  open,
  onClose,
  onSave,
  user,
  partner,
}) => {
  const [email, setEmail] = useState<string | undefined>(user.email);
  const [partnerName, setPartnerName] = useState<string | undefined>(partner?.partner_name);
  const [listId, setListId] = useState<number | undefined>(partner?.crm_list_id);
  const [dataSourceId, setDataSourceId] = useState<number | undefined>(user.data_source_id);
 
  useEffect(() => {
    setEmail(user.email);
    setPartnerName(partner?.partner_name);
    setListId(partner?.crm_list_id);
    setDataSourceId(user.data_source_id);
  }, [user, partner]); // Ensure this effect runs whenever `user` or `partner` changes

  const handleSave = () => {
    onSave({ email, partnerName: partnerName!, listId, dataSourceId });
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Edit User</DialogTitle>
      <DialogContent>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoFocus
          value={email}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setEmail(e.target.value)
          }
        />
        <Typography style={{ color: "#8B0000", fontSize: "0.7rem" }}>*Updating the partner name for this user will apply the change to all users associated with the same partner.</Typography>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="partnerName"
          label="Partner Name"
          type="text"
          id="partnerName"
          value={partnerName}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setPartnerName(e.target.value)
          }
        />
        <Typography style={{ color: "#8B0000", fontSize: "0.7rem" }}>*Updating the list ID for this user will apply the change to all users associated with the same partner.</Typography>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="listId"
          label="List ID"
          type="text"
          id="listId"
          value={listId === 0 ? "" : listId}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setListId(
              e.target.value === "" ? 0 : Number(e.target.value)
            )
          }
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="dataSourceId"
          label="Data Source ID"
          type="text"
          id="dataSourceId"
          value={dataSourceId === 0 ? "" : dataSourceId}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setDataSourceId(
              e.target.value === "" ? 0 : Number(e.target.value)
            )
          }
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditUserDialog;
