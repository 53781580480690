import React from "react";
import { Container, Typography, useMediaQuery } from "@mui/material";
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer, Legend } from "recharts";

interface Lead {
  id: string;
  created: string;
  full_name: string;
  business_name: string;
  email: string;
  status: string;
  stage: string;
  crm_id: string;
}

interface PieChartProps {
  leads?: Lead[] | null;
}

const CustomPieChart: React.FC<PieChartProps> = ({ leads = [] }) => {
  const stages = [
    "Specialty",
    "Renewal",
    "Opportunity",
    "Nurture",
    "Lead",
    "Closed - Won",
    "Closed - Lost",
  ];

  // Calculate the total number of leads
  const totalLeads = leads?.length || 0;
  
  // Map the stages to data with calculated values
  const data = stages.map((stage) => {
    const count = leads?.filter((lead) => lead.stage === stage).length || 0;
    const percentage = totalLeads > 0 ? (count / totalLeads) * 100 : 0;
    return {
      name: stage,
      value: count,
      percentage: percentage.toFixed(2), // Format percentage to 2 decimal places
    };
  });

  const COLORS = [
    "#FF6F61",
    "#6B5B95",
    "#88B04B",
    "#FFA500",
    "#92A8D1",
    "#00CED1",
    "#B565A7",
  ];

  // Custom formatter for the legend to show percentages
  const renderLegend = (value: string, entry: any) => {
    const payloadPercentage = entry?.payload?.percentage || '0.00';
    return `${value}: ${payloadPercentage}%`;
  };

  // Use MediaQuery to determine screen size
  const isXs = useMediaQuery('(max-width:600px)');
  const isSm = useMediaQuery('(max-width:960px)');
  const isMd = useMediaQuery('(max-width:1280px)');
  const isLg = useMediaQuery('(max-width:1920px)');
  const isXl = useMediaQuery('(min-width:1920px)');

  // Determine dynamic properties based on screen size
  const cx = isXs || isSm ? "50%" : isMd ? "10%" : "40%";
  const cy = isXs || isSm ? "50%" : isMd ? "40%" : "40%";
  const outerRadius = isXs || isSm ? "100%" : isMd ? '100%' : isLg ? '80%' : isXl ? '80%' : "80%";
  const fontSize = isXs ? '8px' : isSm ? '10px' : isMd ? '12px' : '14px';

  return (
    <Container>
      <Typography variant="h5" align="left">By Stage</Typography>
      <ResponsiveContainer width="100%" height={400}>
        <PieChart>
          <Pie
            data={data}
            cx={cx}
            cy={cy}
            labelLine={false}
            outerRadius={outerRadius}
            fill="#8884d8"
            dataKey="value"
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
          <Tooltip />
          <Legend 
            layout="vertical" 
            align="left" 
            verticalAlign="bottom" 
            formatter={renderLegend} // Use the custom formatter
            wrapperStyle={{ fontSize }} // Apply dynamic font size
          />
        </PieChart>
      </ResponsiveContainer>
    </Container>
  );
};

export default CustomPieChart;
