// import React from 'react';
// import { Navigate } from 'react-router-dom';

// interface PrivateRouteProps {
//   isLoggedIn: boolean;
//   element: React.ReactElement; 
//   requiredRole?: "admin" | "super-admin" | "user" | null;
//   userRole?: "admin" | "super-admin" | "user" | null;
// }

// const PrivateRoute: React.FC<PrivateRouteProps> = ({
//   isLoggedIn,
//   element,
//   requiredRole,
//   userRole
// }) => {
//   // If not logged in, redirect to login page
//   if (!isLoggedIn) {
//     return <Navigate to="/" />;
//   }

//   // If logged in but lacks the required role, redirect to home page
//   if (requiredRole && userRole !== requiredRole && userRole !== "super-admin") {
//     return <Navigate to="/home" />;
//   }

//   // If logged in and has the required role or is a super-admin, render the element
//   return element;
// };

// export default PrivateRoute;

import React from 'react';
import { Navigate } from 'react-router-dom';
import PasswordReset from './passwordReset';

interface PrivateRouteProps {
  isLoggedIn: boolean;
  element: React.ReactElement;
  requiredRole?: "admin" | "super-admin" | "user" | null;
  userRole?: "admin" | "super-admin" | "user" | null;
  isImposter?: boolean;
  isCodeVerified?: boolean;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  isLoggedIn,
  element,
  requiredRole,
  userRole,
  isImposter,
  isCodeVerified
}) => {
  // console.log("isImposter: ", isImposter)
    // console.log("private route: ", isLoggedIn, requiredRole, userRole);
    // console.log("isImposter: ", isImposter)
  // If not logged in, redirect to login page
  if (!isLoggedIn && isCodeVerified){
    return element;
    // return <Navigate to="/password-reset" />;
  }
  if (!isLoggedIn) {
    return <Navigate to="/" />;
  }

  // if (isImposter || isImposter === undefined) {
  //   return <Navigate to="/users-directory" />;
  // }
  
  // If logged in but lacks the required role, redirect to home page
  // if ((requiredRole && userRole !== requiredRole && userRole !== "super-admin")) {
  //   return <Navigate to="/home" />;
  // }

  // If logged in and has the required role or is a super-admin, render the element
  return element;
};

export default PrivateRoute;
