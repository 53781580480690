import React, { useState, useEffect, ChangeEvent, FormEvent } from "react";
import { Container, TextField, Button, Box, Typography } from "@mui/material";
import axios from "axios";
import { Navigate, useNavigate } from "react-router";

interface FormData {
  name: string;
  message: string;
}

const ContactUs = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState<FormData>({
    name: "",
    message: "",
  });
  const [submitted, setSubmitted] = useState<boolean>(false); // State to track form submission status

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      await axios.post(
        "https://backend.partner-bigthinkcapital.com/api/contact-us",
        {
          name: formData.name,
          message: formData.message,
        },
        { withCredentials: true }
      );
      setSubmitted(true); // Update state to show confirmation message
    } catch (error) {
      console.error("Error sending message:", error);
      alert("Failed to send message.");
    }
  };

  const gradientStyle: React.CSSProperties = {
    background: "linear-gradient(90deg, rgb(24, 94, 157), rgb(45, 145, 195))",
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          marginBottom: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
          padding: "20px",
          borderRadius: "10px",
        }}
      >
        <img
          src="/btc_logo_transparent.png"
          alt="Logo"
          style={{ marginBottom: "10%", width: "70%", height: "70%" }}
        />
        {submitted ? (
          <Container>
            <Typography variant="h5" gutterBottom>
              We have recieved your inquiry. A member of our Partnership Team
              will contact you shortly.
            </Typography>
            <Button onClick={() => navigate("/home")}>
              Go Back to Dashboard
            </Button>
          </Container>
        ) : (
          <>
            <Typography variant="h4" gutterBottom>
              Contact Us
            </Typography>
            <Box component="form" onSubmit={handleSubmit} mt={2} width="100%">
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="name"
                label="Name"
                name="name"
                value={formData.name}
                onChange={handleChange}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="message"
                label="Message"
                name="message"
                multiline
                rows={4}
                value={formData.message}
                onChange={handleChange}
              />
              <Button
                type="submit"
                fullWidth
                style={gradientStyle}
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Submit
              </Button>
            </Box>
          </>
        )}
      </Box>
    </Container>
  );
};

export default ContactUs;
