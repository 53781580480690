import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";

interface UserLockConfirmationProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  isLocked: boolean;
}

const UserLockConfirmation: React.FC<UserLockConfirmationProps> = ({
  open,
  onClose,
  onConfirm,
  isLocked,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {isLocked ? "Unlock User" : "Lock User"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {isLocked
            ? "Are you sure you want to unlock this user?"
            : "Are you sure you want to lock this user?"}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={onConfirm} color="primary" autoFocus>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UserLockConfirmation;
