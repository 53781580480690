import React, { useState, ChangeEvent, FormEvent } from "react";
import { Button, TextField, Container, Typography, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { BounceLoader } from "react-spinners";

interface AddUserFormProps {
  role: "admin" | "super-admin" | "user" | null;
}

interface ValidationErrors {
  email?: string;
  password?: string;
  partnerName?: string;
  listId?: string;
  dataSourceId?: string;
}

const AddUserForm: React.FC<AddUserFormProps> = ({ role }) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [listId, setListId] = useState<number | undefined>();
  const [dataSourceId, setDataSourceId] = useState<number | undefined>();
  const [partnerName, setPartnerName] = useState<string | undefined>();
  const [errors, setErrors] = useState<ValidationErrors>({});
  const [addUserError, setAddUserError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false); // Loading state

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const validationErrors: ValidationErrors = {};
    if (!email) {
      validationErrors.email = "Email is required";
    }
    if (!password) {
      validationErrors.password = "Password is required";
    }
    if (listId === undefined) {
      validationErrors.listId = "List ID is required";
    }
    if (dataSourceId === undefined) {
      validationErrors.dataSourceId = "Data Source ID is required";
    }
    if (partnerName === undefined) {
      validationErrors.partnerName = "Partner Name is required";
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    setLoading(true); // Start loading

    try {
      await axios.post(
        "https://backend.partner-bigthinkcapital.com/api/add-user",
        { email, password, partnerName, listId, dataSourceId },
        { withCredentials: true }
      );
      navigate("/users-directory");
    } catch (error: any) {
      if (error.response && error.response.data && error.response.data.error) {
        // Display the error message returned from the backend
        setAddUserError(error.response.data.error);
      } else {
        // Generic error message if the error response is not what you expect
        setAddUserError("Error adding user. Please try again.");
      }
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const gradientStyle: React.CSSProperties = {
    background: "linear-gradient(90deg, rgb(24, 94, 157), rgb(45, 145, 195))",
  };

  if (role !== "admin" && role !== "super-admin") {
    return <Typography variant="body1">Access denied</Typography>;
  }

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          marginBottom: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
          padding: "20px",
          borderRadius: "10px",
        }}
      >
        {loading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
          >
            <BounceLoader
              size={30}
              color="#1E90FF" // Dodger Blue
              loading={loading}
              cssOverride={{ margin: "0 10px" }} // Add margin to space them out
              speedMultiplier={1.5} // Faster animation
            />
            <BounceLoader
              size={30}
              color="#1E90FF" // Dodger Blue
              loading={loading}
              cssOverride={{ margin: "0 10px" }} // Add margin to space them out
              speedMultiplier={1.5} // Faster animation
            />
            <BounceLoader
              size={30}
              color="#1E90FF" // Dodger Blue
              loading={loading}
              cssOverride={{ margin: "0 10px" }} // Add margin to space them out
              speedMultiplier={1.5} // Faster animation
            />
            <BounceLoader
              size={30}
              color="#1E90FF" // Dodger Blue
              loading={loading}
              cssOverride={{ margin: "0 10px" }} // Add margin to space them out
              speedMultiplier={1.5} // Faster animation
            />
          </Box>
        ) : (
          <>
            <img
              src="/btc_logo_transparent.png"
              alt="Logo"
              style={{ marginBottom: "10%", width: "70%", height: "70%" }}
            />
            <Typography variant="h5" gutterBottom>
              Set User Credentials
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box
                component="form"
                onSubmit={handleSubmit}
                sx={{ mt: 2, width: "100%" }}
              >
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoFocus
                  value={email}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setEmail(e.target.value)
                  }
                  error={!!errors.email}
                  helperText={errors.email || " "}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  value={password}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setPassword(e.target.value)
                  }
                  error={!!errors.password}
                  helperText={errors.password || " "}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="Partner Name"
                  label="Partner Name"
                  type="text"
                  id="partnerName"
                  value={partnerName}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setPartnerName(e.target.value)
                  }
                  error={!!errors.partnerName}
                  helperText={errors.partnerName || " "}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="List ID"
                  label="List ID"
                  type="text"
                  id="listId"
                  value={listId === 0 ? "" : listId}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setListId(
                      e.target.value === "" ? 0 : Number(e.target.value)
                    )
                  }
                  error={!!errors.listId}
                  helperText={errors.listId || " "}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="Data Source ID"
                  label="Data Source ID (To Upload Leads to)"
                  type="text"
                  id="dataSourceId"
                  value={dataSourceId === 0 ? "" : dataSourceId}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setDataSourceId(
                      e.target.value === "" ? 0 : Number(e.target.value)
                    )
                  }
                  error={!!errors.dataSourceId}
                  helperText={errors.dataSourceId || " "}
                />
                <Typography
                  style={{ color: "#8B0000", fontSize: "0.85rem" }}
                  variant="body1"
                  gutterBottom
                >
                  *Assigning a list with filters that delay user additions will
                  prevent immediate viewing of new leads upon upload. Please
                  inform the partner or avoid using such lists.
                </Typography>
                {addUserError && (
                  <Typography
                    color="#B30000"
                    variant="body2"
                    align="center"
                    sx={{ mt: 5 }}
                  >
                    {addUserError}
                  </Typography>
                )}
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  style={gradientStyle}
                  sx={{ mt: 3, mb: 2 }}
                >
                  Add User
                </Button>
              </Box>
            </Box>
          </>
        )}
      </Box>
    </Container>
  );
};

export default AddUserForm;
