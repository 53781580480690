import React, { useState } from "react";
import PieChart from "./visualizations/pieChart";
import LineGraph from "./visualizations/lineGraph";
import BarGraph from "./visualizations/barGraph";
import Summary from "./visualizations/summary";
import {
  Container,
  Grid,
  TextField,
  Box,
  IconButton,
  Typography,
  Collapse,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { parseISO } from "date-fns";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

interface Lead {
  id: string;
  full_name: string;
  created: string;
  business_name: string;
  email: string;
  status: string;
  stage: string;
  crm_id: string;
}

interface VisualizationsProps {
  leads?: Lead[] | null;
  filters: { startDate: Date | null; endDate: Date | null };
  handleDateChange: (name: "startDate" | "endDate", date: Date | null) => void;
}

const Visualizations: React.FC<VisualizationsProps> = ({ leads = [] }) => {
  const [filters, setFilters] = useState<{
    startDate: Date | null;
    endDate: Date | null;
  }>({
    startDate: null,
    endDate: null,
  });
  const [collapsed, setCollapsed] = useState(false);

  const handleDateChange = (
    name: "startDate" | "endDate",
    date: Date | null
  ) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: date,
    }));
  };

  const handleDateLocalChange = (
    name: "startDate" | "endDate",
    date: Date | null
  ) => {
    handleDateChange(name, date);
  };

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  const filteredLeads = leads?.filter((lead) => {
    const createdAt = parseISO(lead.created);
    const startDate = filters.startDate ? new Date(filters.startDate) : null;
    let endDate = filters.endDate ? new Date(filters.endDate) : null;

    // Add one day to endDate
    if (endDate) {
      endDate.setDate(endDate.getDate() + 1);
    }

    return (
      (!startDate || createdAt >= startDate) &&
      (!endDate || createdAt < endDate)
    );
  });

  const gradientStyle = {
    background: "linear-gradient(90deg, rgb(24, 94, 157), rgb(45, 145, 195))",
  };

  return (
    <Container>
      <Grid mt={2} container spacing={2}>
        <Grid marginLeft={3.3} item xs={4} md={2}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Start Date"
              value={filters.startDate}
              onChange={(date) => handleDateLocalChange("startDate", date)}
              slots={{
                textField: (params: any) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    size="small"
                    fullWidth
                    margin="dense"
                    InputLabelProps={{ shrink: true }}
                  />
                ),
              }}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={4} md={2}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="End Date"
              value={filters.endDate}
              onChange={(date) => handleDateLocalChange("endDate", date)}
              slots={{
                textField: (params: any) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    size="small"
                    fullWidth
                    margin="dense"
                    InputLabelProps={{ shrink: true }}
                  />
                ),
              }}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>
      <Container>
        <Box sx={{ border: "1px solid #000", borderRadius: 2, mt: 4 }}>
          <Summary leads={filteredLeads} />
        </Box>
        <Box sx={{ border: "1px solid #000", borderRadius: 2, mt: 4 }}>
          <LineGraph leads={filteredLeads} />
        </Box>
        <Box sx={{ border: "1px solid #000", borderRadius: 2, mt: 4 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              borderBottom: "1px solid blue",
              borderWidth: "1px",
              borderStyle: "solid",
              borderRadius: "4px",
              width: "100%",
              ...gradientStyle,
            }}
          >
            <IconButton
              onClick={toggleCollapse}
              sx={{
                border: "1px solid blue",
                borderRadius: "2px",
                color: "white",
              }}
            >
              {collapsed ? <ExpandMoreIcon /> : <ExpandLessIcon />}
            </IconButton>
            <Typography
              sx={{ color: "white", marginRight: "auto", marginLeft: "2%" }}
            >
              Leads Breakdown
            </Typography>
          </Box>
          <Collapse in={!collapsed}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Box sx={{ borderRight: "1px solid #000" }}>
                  <PieChart leads={filteredLeads} />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box>
                  <BarGraph leads={filteredLeads} />
                </Box>
              </Grid>
            </Grid>
          </Collapse>
        </Box>
      </Container>
    </Container>
  );
};

export default Visualizations;
