import React, { useState, CSSProperties } from "react";
import { Button, TextField, Container, Typography, Box } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";

interface ValidationError {
  password?: string;
  reenteredPassword?: string;
}

interface PasswordResetProps {
  setIsCodeVerified: React.Dispatch<React.SetStateAction<boolean>>;
}

const PasswordReset: React.FC<PasswordResetProps> = ({ setIsCodeVerified }) => {
  const navigate = useNavigate();
  const [password, setPassword] = useState<string>("");
  const [reenteredPassword, setReenteredPassword] = useState<string>("");
  const [errors, setErrors] = useState<ValidationError>({});
  const location = useLocation();
  const email = location.state?.email || ""; // Retrieve email from state
  // console.log("Email:", email); // For debugging

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    let validationErrors: ValidationError = {};

    if (!password) {
      validationErrors.password = "Password is required";
    }

    if (password !== reenteredPassword) {
      validationErrors.reenteredPassword = "Passwords do not match";
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      try {
        await axios.post(
          "https://backend.partner-bigthinkcapital.com/api/set-new-password",
          {
            email,
            password,
          }
        );
        setIsCodeVerified(false);
        navigate("/");
      } catch (error) {
        console.error("Error resetting password:", error);
      }
    }
  };

  const gradientStyle: React.CSSProperties = {
    background: "linear-gradient(90deg, rgb(24, 94, 157), rgb(45, 145, 195))",
  };

  return (
    <Container component="main">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img
          src="/btc_logo_transparent.png"
          alt="Logo"
          style={{ marginBottom: "2%", width: "25%", height: "25%" }}
        />
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{ mt: 1, width: "100%", maxWidth: "30%" }}
        >
          <Typography>New Password:</Typography>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="password"
            label="Password"
            name="password"
            autoComplete="password"
            autoFocus
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Typography mt={2}>Re-enter New Password:</Typography>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="reenteredPassword"
            label="Re-enter Password"
            name="reenteredPassword"
            autoComplete="password"
            value={reenteredPassword}
            onChange={(e) => setReenteredPassword(e.target.value)}
          />
          {Object.keys(errors).map((key) => (
            <Typography key={key} color="#B30000">
              {errors[key as keyof ValidationError]}
            </Typography>
          ))}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            style={gradientStyle}
            sx={{ mt: 3, mb: 2 }}
          >
            Set Password
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default PasswordReset;
