import { Status } from './statusDefinitions';

export const statusCategories: Record<string, Status[]> = {
    //yellow
    'Total in Contact': [
      'Renewal - Eligible', 
      'Renewal - Hot Lead', 
      'Renewal - Call Back', 
      'Renewal - Attempt 1', 
      'Renewal - Attempt 2', 
      'Renewal - Attempt 3', 
      'Renewal - Attempt 4', 
      'Renewal - Attempt 5',   
      'Hot Lead',
      'Cold Lead',
      'Pending Discovery Call',
      'Call Back',
      'Contacting',
    ],
    //blue
    'Total in Progress': [
      'Specialty Prospect ',
      'Specialty Submitted',
      'Specialty Offers In',
      'Specialty Contracts Out',
      'Specialty Contracts In',
      'Specialty Pending Additional Info',
      'Specialty Review',
      'Renewal - App Out',
      'Renewal - App In',
      'Renewal - Submission',
      'Renewal - Offer In',
      'Renewal - Reviewing Offer',
      'Renewal - Contracts Out',
      'Renewal - Contracts In',
      'App Out',
      'App Out - No Drip',
      'Viewed DocuSign',
      'Viewed DocuSign - Marketing',
        'Offers In',
        'Steal- Working',
        'Steal- Dead',
        'Docs Recieved',
        'Pending Additional INFO ',
        'App In - Collecting Docs',
        'Sent to Operations',
        'Resubmit to Lenders',
        'Submitted to Funders',
        'Merchant Reviewing Offers',
        'Offer Accepted - Collecting Closing Docs',
        'Ready to Order Contracts',
        'Contracts Out',
        'Contracts Signed - Final Stips'
    ],
    //red
    'Total Declined': [
      'Specialty Funders Declined',
      'Specialty Declined',
      'Specialty Merchant Declined',
      'Renewal - Merchant Decline',
      'Renewal - Funder Declined',
      'Renewal - Not Interested',
      'Renewals - DNQ (Check Back Later)',
      'Clawback',
      'Defaulted', 
      'In Collections',
      'In Legal',
      'Fraud ',
      'Funders Declined',
      'Funders Decline - Killed In Final',
      'Merchant Declined',
      'Do Not Contact',
      'False Information',
      'Not Interested - No Texting',
      'Customer is a Startup',
      'DNQ - Good Credit',
      'Extra Payout',
      'Already Funded by Competitor',
      'DNQ - Personal Banks',
      'DNQ - Low Rev',
      'Not Ready - Future',
      'Not Responsive',
      'Dead',
      'Does Not Qualify',
    ],
    //green
    'Total Funded': [
      'Funded - In Repayment',
      'Specialty Funded',
      'Funded - RC Program',
      'Funded - Renewal Declined',
      'Funded - DNC',
      'Funded - Not Interested',
      'Paid in Full',
    ],
  };  