import React, { useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Container,
  Tabs,
  Tab,
  Collapse,
  IconButton,
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import PercentIcon from "@mui/icons-material/Percent";
import NumbersIcon from "@mui/icons-material/Numbers";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { statusCategories } from "../statusCategories";
import { Status } from "../statusDefinitions";

interface Lead {
  id: string;
  full_name: string;
  created: string;
  business_name: string;
  email: string;
  status: string;
  stage: string;
  crm_id: string;
}

interface SummaryProps {
  leads?: Lead[] | null;
}

const Summary: React.FC<SummaryProps> = ({ leads = [] }) => {
  const [viewMode, setViewMode] = useState<"numbers" | "percentages">(
    "numbers"
  );
  const [collapsed, setCollapsed] = useState(false);

  const boxStyles = {
    padding: 2,
    borderRadius: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    // width: {md: '50', lg: '140', xl: '140'}, // Dynamic width based on screen size
    // height: {md: '30', lg: '120', xl: '120' },
    width: 140, // Fixed width
    height: 120, // Fixed height
    position: "relative",
    textAlign: "center",
  };

  const headerStyles = {
    whiteSpace: "nowrap",
    textAlign: "center",
    fontSize: "1.25rem", // Smaller font size for headers
  };

  const numberStyles = {
    fontSize: "1.5rem", // Smaller font size for numbers
  };

  const arrowStyles = {
    position: "absolute",
    right: -33,
    top: "50%",
    transform: "translateY(-50%)",
    padding: "5px",
  };

  const gradientStyle = {
    background: "linear-gradient(90deg, rgb(24, 94, 157), rgb(45, 145, 195))",
  };

  const countLeadsByCategory = (
    leads: Lead[] | null,
    category: string
  ): number | undefined => {
    const statuses = statusCategories[category];
    return leads?.filter((lead) => statuses.includes(lead.status as Status))
      .length;
  };

  const getTotalLeads = () => {
    return leads?.length ?? 0;
  };

  const getOther = () => {
    return (
      getTotalLeads() -
      ((countLeadsByCategory(leads, "Total in Contact") ?? 0) +
        (countLeadsByCategory(leads, "Total in Progress") ?? 0) +
        (countLeadsByCategory(leads, "Total Declined") ?? 0) +
        (countLeadsByCategory(leads, "Total Funded") ?? 0))
    );
  };

  const getPercentage = (count: number) => {
    const total = getTotalLeads();
    return total ? ((count / total) * 100).toFixed(0) + "%" : "0%";
  };

  const handleTabChange = (
    event: React.SyntheticEvent,
    newValue: "numbers" | "percentages"
  ) => {
    setViewMode(newValue);
  };

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  // Calculate the total width of the summary fields and additional padding
  const summaryFieldsCount = 6;
  const summaryFieldWidth = 140; // Width of each box
  const spacing = 16; // Spacing between items (spacing={4} -> 4 * 4 = 16px)
  const paddingX = 70; // Horizontal padding
  const paddingY = 6; // Vertical padding
  const totalWidth =
    summaryFieldsCount * summaryFieldWidth +
    (summaryFieldsCount - 1) * spacing +
    2 * paddingX;

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          position: "relative",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottom: "1px solid blue",
            borderWidth: "1px",
            borderStyle: "solid",
            borderRadius: "4px",
            ...gradientStyle,
          }}
        >
          <IconButton
            onClick={toggleCollapse}
            sx={{
              border: "1px solid blue",
              borderRadius: "2px",
              color: "white",
            }}
          >
            {collapsed ? <ExpandMoreIcon /> : <ExpandLessIcon />}
          </IconButton>
          <Typography sx={{ color: "white", marginRight: 'auto', marginLeft: '2%' }}>
            Summary
          </Typography>
        </Box>
        <Collapse in={!collapsed}>
          <Box sx={{ paddingY: paddingY }}>
            <Grid
              container
              spacing={4}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item>
                <Box
                  sx={{
                    ...boxStyles,
                    // backgroundColor: "#b0b0b0",
                    border: "1px solid #000",
                    color: "#000000",
                    borderRadius: 2,
                  }}
                >
                  <Typography sx={headerStyles}>Total Leads</Typography>
                  <Typography sx={numberStyles}>
                    {viewMode === "numbers"
                      ? getTotalLeads()
                      : getPercentage(getTotalLeads())}
                  </Typography>
                  <Box sx={arrowStyles}>
                    <ArrowForwardIcon style={{ color: "#000000" }} />
                  </Box>
                </Box>
              </Grid>
              <Grid item>
                <Box
                  sx={{
                    ...boxStyles,
                    backgroundColor: "#FFD180",
                    color: "#000000",
                    borderRadius: 2,
                  }}
                >
                  <Typography sx={headerStyles}>In Contact</Typography>
                  <Typography sx={numberStyles}>
                    {viewMode === "numbers"
                      ? countLeadsByCategory(leads, "Total in Contact")
                      : getPercentage(
                          countLeadsByCategory(leads, "Total in Contact") ?? 0
                        )}
                  </Typography>
                </Box>
              </Grid>
              <Grid item>
                <Box
                  sx={{
                    ...boxStyles,
                    backgroundColor: "#90CAF9",
                    color: "#000000",
                    borderRadius: 2,
                  }}
                >
                  <Typography sx={headerStyles}>In Progress</Typography>
                  <Typography sx={numberStyles}>
                    {viewMode === "numbers"
                      ? countLeadsByCategory(leads, "Total in Progress")
                      : getPercentage(
                          countLeadsByCategory(leads, "Total in Progress") ?? 0
                        )}
                  </Typography>
                </Box>
              </Grid>
              <Grid item>
                <Box
                  sx={{
                    ...boxStyles,
                    backgroundColor: "#FFABAB",
                    color: "#000000",
                    borderRadius: 2,
                  }}
                >
                  <Typography sx={headerStyles}>Declined</Typography>
                  <Typography sx={numberStyles}>
                    {viewMode === "numbers"
                      ? countLeadsByCategory(leads, "Total Declined")
                      : getPercentage(
                          countLeadsByCategory(leads, "Total Declined") ?? 0
                        )}
                  </Typography>
                </Box>
              </Grid>
              <Grid item>
                <Box
                  sx={{
                    ...boxStyles,
                    backgroundColor: "#A5D6A7",
                    color: "#000000",
                    borderRadius: 2,
                  }}
                >
                  <Typography sx={headerStyles}>Funded</Typography>
                  <Typography sx={numberStyles}>
                    {viewMode === "numbers"
                      ? countLeadsByCategory(leads, "Total Funded")
                      : getPercentage(
                          countLeadsByCategory(leads, "Total Funded") ?? 0
                        )}
                  </Typography>
                </Box>
              </Grid>
              <Grid item>
                <Box
                  sx={{
                    ...boxStyles,
                    backgroundColor: "#b0b0b0",
                    color: "#000000",
                    borderRadius: 2,
                  }}
                >
                  <Typography sx={headerStyles}>Other</Typography>
                  <Typography sx={numberStyles}>
                    {viewMode === "numbers"
                      ? getOther()
                      : getPercentage(getOther())}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Tabs
            value={viewMode}
            onChange={handleTabChange}
            aria-label="view mode tabs"
            sx={{ minHeight: 32 }}
            TabIndicatorProps={{
              style: {
                display: "none",
              },
            }}
          >
            <Tab
              icon={<PercentIcon />}
              value="percentages"
              aria-label="percentages"
              sx={{
                minWidth: 32,
                minHeight: 32,
                padding: 0,
                borderRadius: "0 0 0 10px",
                borderRight: "1px solid blue",
                borderTop: "1px solid blue",
                backgroundColor:
                  viewMode === "percentages" ? "lightgray" : "white",
              }}
            />
            <Tab
              icon={<NumbersIcon />}
              value="numbers"
              aria-label="numbers"
              sx={{
                minWidth: 32,
                minHeight: 32,
                padding: 0,
                borderRadius: "0 10px 0 0",
                borderRight: "1px solid blue",
                borderTop: "1px solid blue",
                backgroundColor: viewMode === "numbers" ? "lightgray" : "white",
              }}
            />
          </Tabs>
        </Collapse>
      </Box>
    </Box>
  );
};

export default Summary;
