import React, { useState, useEffect, ChangeEvent } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
  Typography,
  Container,
  TextField,
  Box,
  Grid,
  TablePagination,
  Tooltip,
  IconButton,
  Popover,
  Button,
} from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { format, parseISO, isValid } from "date-fns";
import InfoIcon from "@mui/icons-material/Info";
import { getStatusDefinition, Status } from "./statusDefinitions";
import DescriptionIcon from "@mui/icons-material/Description";
import { Autocomplete } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import UploadLeadForm from "./uploadLeadForm";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import * as XLSX from "xlsx";
import axios from "axios";
import { BounceLoader } from "react-spinners";

// TypeScript Interfaces
interface Lead {
  id: string;
  full_name: string;
  created: string;
  business_name: string;
  email: string;
  status: string;
  stage: string;
  crm_id: string;
}

interface Partner {
  partner_name: string;
  crm_list_id: number;
}

interface DashboardProps {
  partner: Partner | null;
  leads: Lead[] | null;
  setLeads: React.Dispatch<React.SetStateAction<Lead[] | null>>;
  loading: boolean;
  filters: { startDate: Date | null; endDate: Date | null };
  handleDateChange: (name: "startDate" | "endDate", date: Date | null) => void;
  order: "asc" | "desc";
  orderBy: string;
  handleRequestSort: (property: keyof Lead) => void;
}

interface Filters {
  businessName: string;
  status: string | null;
  stage: string | null;
  startDate: Date | null;
  endDate: Date | null;
}

// Custom MUI Theme
const theme = createTheme({
  components: {
    MuiTableSortLabel: {
      styleOverrides: {
        root: {
          "&.Mui-active": {
            color: "white",
          },
          "&.Mui-active .MuiTableSortLabel-icon": {
            color: "white !important",
          },
        },
        icon: {
          color: "white !important",
        },
      },
    },
  },
});

const Dashboard: React.FC<DashboardProps> = ({
  partner,
  leads,
  setLeads,
  loading,
  order,
  orderBy,
  handleRequestSort,
}) => {
  const [filters, setFilters] = useState<Filters>({
    businessName: "",
    status: null,
    stage: null,
    startDate: null,
    endDate: null,
  });
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [dialogLoading, setDialogLoading] = useState(false);
  const [dialogProgress, setDialogProgress] = useState(0);
  const [dialogMessage, setDialogMessage] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedLead, setSelectedLead] = useState<Lead | null>(null);
  const [fileBase64, setFileBase64] = useState("");
  const [selectedType, setSelectedType] = useState<string | null>(null);

  // Function to handle file selection
  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      convertFileToBase64(file, selectedType!);
    }
  };

  // Function to convert file to base64
  const convertFileToBase64 = (file: File, type: string) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      const base64String = (reader.result as string).split(",")[1]; // Extract only the base64 part
      handleSubmitForm(type, base64String);
    };
    reader.onerror = () => {
      console.error("Error reading file");
    };
  };

  const handleFilterChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  const handleStatusChange = (
    event: React.SyntheticEvent,
    value: string | null
  ) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      status: value,
    }));
  };

  const handleStageChange = (
    event: React.SyntheticEvent,
    value: string | null
  ) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      stage: value,
      status: null, // Clear the status when stage changes
    }));
  };

  const handleDateChange = (name: keyof Filters, value: Date | null) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  const handleDateLocalChange = (name: keyof Filters, value: Date | null) => {
    handleDateChange(name, value);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleIconClick = (
    event: React.MouseEvent<HTMLElement>,
    lead: Lead
  ) => {
    setSelectedLead(lead);
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
    setSelectedLead(null);
  };

  const handleSubmitForm = async (type: string, fileBase64: string) => {
    let bodyData;
    // const base64FileContent = btoa(
    //   unescape(encodeURIComponent("application/octet-stream"))
    // );
    console.log("file content: ", fileBase64);
    console.log("type: ", type);
    console.log("selectedLead crm id: ", selectedLead?.crm_id);
    if (type === "bankstatement") {
      bodyData = {
        file_content: fileBase64, // This should be the actual data you want to send
        file_name: `bankstatement_${partner?.partner_name}`,
        description: "Bankstatement",
        doc_type: "3",
      };
    } else if (type === "application") {
      bodyData = {
        file_content: fileBase64, // This should be the actual data you want to send
        file_name: `application_${partner?.partner_name}`,
        description: "Application",
        doc_type: "18793",
      };
    }

    try {
      if (!selectedLead?.crm_id) {
        console.error("Selected lead is not defined");
        return;
      }
      await axios.post(
        "https://backend.partner-bigthinkcapital.com/api/upload-document",
        { contactCRMID: selectedLead.crm_id, bodyData: bodyData },
        { withCredentials: true }
      );
    } catch (error) {
      console.error("Request failed:", error);
    }

    handleClosePopover();
  };

  // Handle button click and trigger file selection
  const handleButtonClick = (type: string) => {
    setSelectedType(type);
    document.getElementById("file-upload-input")?.click();
  };

  const filteredLeads = leads?.filter((lead) => {
    const createdAt = parseISO(lead.created);
    const startDate = filters.startDate ? new Date(filters.startDate) : null;
    const endDate = filters.endDate ? new Date(filters.endDate) : null;
    return (
      (!startDate || createdAt >= startDate) &&
      (!endDate ||
        createdAt <= new Date(endDate.getTime() + 24 * 60 * 60 * 1000)) &&
      (lead.business_name === null ||
        lead.business_name
          ?.toLowerCase()
          .includes(filters.businessName.toLowerCase())) &&
      (!filters.status || filters.status === lead.status) &&
      (!filters.stage || filters.stage === lead.stage)
    );
  });

  function getToolTipDefinitions(status: string): string | undefined {
    const definition = getStatusDefinition(status as Status);
    return definition;
  }
  const downloadPDF = () => {
    const doc = new jsPDF();

    autoTable(doc, {
      head: [
        ["Created", "Full Name", "Business Name", "Email", "Stage", "Status"],
      ],
      body: filteredLeads?.map((lead) => [
        format(parseISO(lead.created), "MM/dd/yyyy"),
        lead.full_name,
        lead.business_name,
        lead.email,
        lead.stage,
        lead.status,
      ]),
    });

    doc.save("BTC_Dashboard.pdf");
  };

  const handleUploadLead = async (formData: {
    firstName: string;
    lastName: string;
    businessName: string;
    email: string;
  }) => {
    const { firstName, lastName, businessName, email } = formData;
    const created = new Date().toISOString();
    setDialogLoading(true); // Set loading to true
    setDialogMessage("");
    try {
      // Perform the backend operations
      await axios.post(
        "https://backend.partner-bigthinkcapital.com/api/create-lead",
        { firstName, lastName, created, businessName, email },
        { withCredentials: true }
      );

      const resp = await axios.get(
        "https://backend.partner-bigthinkcapital.com/api/get-leads",
        {
          withCredentials: true,
        }
      );

      const updatedLeads = resp.data;
      const sortedUpdatedLeads = updatedLeads.sort((a: any, b: any) => {
        const dateA = new Date(a.created).getTime();
        const dateB = new Date(b.created).getTime();
        return dateB - dateA; // Newest first
      });
      setLeads(sortedUpdatedLeads);

      // Now that all backend tasks are complete, show the success message
      setDialogMessage("Lead uploaded successfully!");

      // Display the success message for 1 second before closing the dialog
      setTimeout(() => {
        setDialogLoading(false);
        setDialogOpen(false);
      }, 1000); // Close the dialog after 1 second
    } catch (error) {
      console.error("Error uploading lead:", error);
      setDialogLoading(false);
      setDialogOpen(false);
    }
  };

  const exportToExcel = () => {
    if (leads) {
      // Create a new array of leads without the 'id' field and 'crm_id' field
      const leadsWithoutId = leads.map(({ id, crm_id, ...rest }) => rest);
      const ws = XLSX.utils.json_to_sheet(leadsWithoutId);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Leads");
      XLSX.writeFile(wb, "BTC_Leads.xlsx");
    }
  };

  const openDialog = () => {
    setDialogOpen(true);
  };

  const closeDialog = () => {
    setDialogOpen(false);
    setDialogLoading(false);
    setDialogProgress(0);
    setDialogMessage("");
  };

  const gradientStyle: React.CSSProperties = {
    background: "linear-gradient(90deg, rgb(24, 94, 157), rgb(45, 145, 195))",
  };

  const cellStyle: React.CSSProperties = {
    // padding: "12px 16px",
  };

  return (
    <ThemeProvider theme={theme}>
      <Container
        maxWidth="lg"
        sx={{ paddingLeft: { xs: 1, md: 2 }, paddingRight: { xs: 1, md: 2 } }}
      >
        <Box
          display="flex"
          flexDirection="column"
          sx={{ marginTop: { xs: "2%", md: "1%" } }}
        >
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
          >
            <Typography variant="h4" gutterBottom>
              My BTC Leads
            </Typography>
            <Box display="flex" justifyContent="flex-end">
              <Button
                variant="contained"
                sx={{
                  background:
                    "linear-gradient(90deg, rgb(24, 94, 157), rgb(45, 145, 195))",
                  "&.Mui-disabled": {
                    color: "white", // Keep the text color the same when disabled
                    backgroundColor:
                      "linear-gradient(90deg, rgb(24, 94, 157), rgb(45, 145, 195))", // Keep the background color the same
                  },
                }}
                onClick={exportToExcel}
                startIcon={<InsertDriveFileIcon />}
                disabled={loading}
                className={loading ? "no-pointer-events" : ""}
              >
                Export to Excel
              </Button>
              <Button
                variant="contained"
                sx={{
                  background:
                    "linear-gradient(90deg, rgb(24, 94, 157), rgb(45, 145, 195))",
                  "&.Mui-disabled": {
                    color: "white", // Keep the text color the same when disabled
                    backgroundColor:
                      "linear-gradient(90deg, rgb(24, 94, 157), rgb(45, 145, 195))", // Keep the background color the same
                  },
                  marginLeft: "0.625rem",
                }}
                onClick={downloadPDF}
                startIcon={<SaveAltIcon />}
                disabled={loading}
                className={loading ? "no-pointer-events" : ""}
              >
                Download PDF
              </Button>
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddCircleOutlineIcon />}
                sx={{
                  ...gradientStyle,
                  marginLeft: "0.625rem",
                  "&.Mui-disabled": {
                    color: "white", // Keep the text color the same when disabled
                    backgroundColor:
                      "linear-gradient(90deg, rgb(24, 94, 157), rgb(45, 145, 195))", // Keep the background color the same
                  },
                }}
                onClick={openDialog}
                disabled={loading}
                className={loading ? "no-pointer-events" : ""}
              >
                Upload Lead
              </Button>
            </Box>
          </Box>
          <Box mb={2}>
            <Grid
              container
              spacing={2}
              sx={{ flexWrap: "nowrap", overflowX: "auto" }} // Prevent wrapping and allow horizontal scrolling if needed
            >
              <Grid item xs={true} md={2.4}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="Start Date"
                    value={filters.startDate}
                    onChange={(date) =>
                      handleDateLocalChange("startDate", date)
                    }
                    slots={{
                      textField: (params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          size="small"
                          fullWidth
                          margin="dense"
                          InputLabelProps={{ shrink: true }}
                        />
                      ),
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={true} md={2.4}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="End Date"
                    value={filters.endDate}
                    onChange={(date) => handleDateLocalChange("endDate", date)}
                    slots={{
                      textField: (params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          size="small"
                          fullWidth
                          margin="dense"
                          InputLabelProps={{ shrink: true }}
                        />
                      ),
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={true} md={2.4}>
                <TextField
                  name="businessName"
                  value={filters.businessName}
                  onChange={handleFilterChange}
                  variant="outlined"
                  size="small"
                  fullWidth
                  margin="dense"
                  label="Business Name"
                />
              </Grid>
              <Grid item xs={true} md={2.4}>
                <Autocomplete
                  options={[...new Set(leads?.map((lead) => lead.stage))]}
                  getOptionLabel={(option) => option}
                  value={filters.stage}
                  onChange={handleStageChange as any} // Casting to 'any' to avoid type issues
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      size="small"
                      fullWidth
                      margin="dense"
                      label="Stage"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={true} md={2.4}>
                <Autocomplete
                  options={[
                    ...new Set(filteredLeads?.map((lead) => lead.status)),
                  ]}
                  getOptionLabel={(option: string) => option}
                  value={filters.status}
                  onChange={handleStatusChange as any} // Casting to 'any' to avoid type issues
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      size="small"
                      fullWidth
                      margin="dense"
                      label="Status"
                      disabled={!filters.stage} // Disable when no stage is selected
                    />
                  )}
                  disabled={!filters.stage} // Disable the whole component when no stage is selected
                />
              </Grid>
            </Grid>
          </Box>
          <TableContainer
            sx={{ xs: { width: "10px" } }}
            component={Paper}
            id="dashboard"
          >
            <Table size="small">
              <TableHead>
                <TableRow sx={gradientStyle}>
                  <TableCell
                    sx={{
                      ...cellStyle,
                      color: "white",
                      // xs: { width: '100px' }, sm: { width: "200px" }, md: { width: "300px" }, lg: { width: "400px" }, xl: { width: "500px" },
                      width: "12%",
                      whiteSpace: "nowrap",
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <TableSortLabel
                        active={orderBy === "created"}
                        direction={orderBy === "created" ? order : "asc"}
                        onClick={() => handleRequestSort("created")}
                      >
                        Created
                      </TableSortLabel>
                    </div>
                  </TableCell>
                  <TableCell
                    sx={{
                      ...cellStyle,
                      color: "white",
                      width: "10%",
                      // xs: { width: '100px' }, sm: { width: "200px" }, md: { width: "300px" }, lg: { width: "400px" }, xl: { width: "500px" },
                      whiteSpace: "nowrap",
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      Full Name
                    </div>
                  </TableCell>
                  <TableCell
                    sx={{
                      ...cellStyle,
                      color: "white",
                      width: "15%",
                      // xs: { width: '100px' }, sm: { width: "200px" }, md: { width: "300px" }, lg: { width: "400px" }, xl: { width: "500px" },
                      whiteSpace: "nowrap",
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      Business Name
                    </div>
                  </TableCell>
                  <TableCell
                    sx={{
                      ...cellStyle,
                      color: "white",
                      width: "15%",
                      // xs: { width: '100px' }, sm: { width: "200px" }, md: { width: "300px" }, lg: { width: "400px" }, xl: { width: "500px" },
                      whiteSpace: "nowrap",
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      Email
                    </div>
                  </TableCell>

                  <TableCell
                    sx={{
                      ...cellStyle,
                      color: "white",
                      width: "10%",
                      // xs: { width: '100px' }, sm: { width: "200px" }, md: { width: "300px" }, lg: { width: "400px" }, xl: { width: "500px" },
                      whiteSpace: "nowrap",
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      Stage
                    </div>
                  </TableCell>
                  <TableCell
                    sx={{
                      ...cellStyle,
                      color: "white",
                      width: "10%",
                      // xs: { width: '100px' }, sm: { width: "200px" }, md: { width: "300px" }, lg: { width: "400px" }, xl: { width: "500px" },
                      whiteSpace: "nowrap",
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      Status
                    </div>
                  </TableCell>
                  <TableCell
                    sx={{
                      ...cellStyle,
                      color: "white",
                      width: "8%",
                      // xs: { width: "15%" }, sm: { width: "12%" }, md: { width: "12%" }, lg: { width: "12%" }, xl: { width: "12%" },
                      whiteSpace: "nowrap",
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Typography>Document</Typography>
                    </div>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? ( // Display loading spinner if data is being fetched
                  <TableRow>
                    <TableCell colSpan={7} sx={cellStyle}>
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        height="100%"
                      >
                        <BounceLoader
                          size={30}
                          color="#1E90FF" // Dodger Blue
                          loading={loading}
                          cssOverride={{ margin: "0 0.625rem" }} // Add margin to space them out
                          speedMultiplier={1.5} // Faster animation
                        />
                        <BounceLoader
                          size={30}
                          color="#1E90FF" // Dodger Blue
                          loading={loading}
                          cssOverride={{ margin: "0 0.625rem" }} // Add margin to space them out
                          speedMultiplier={1.5} // Faster animation
                        />
                        <BounceLoader
                          size={30}
                          color="#1E90FF" // Dodger Blue
                          loading={loading}
                          cssOverride={{ margin: "0 0.625rem" }} // Add margin to space them out
                          speedMultiplier={1.5} // Faster animation
                        />
                        <BounceLoader
                          size={30}
                          color="#1E90FF" // Dodger Blue
                          loading={loading}
                          cssOverride={{ margin: "0 0.625rem" }} // Add margin to space them out
                          speedMultiplier={1.5} // Faster animation
                        />
                      </Box>
                    </TableCell>
                  </TableRow>
                ) : filteredLeads && filteredLeads.length > 0 ? (
                  filteredLeads
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    .map((lead) => (
                      <TableRow
                        key={lead.id}
                        sx={{
                          "&:hover": {
                            backgroundColor: "#f5f5f5",
                          },
                        }}
                      >
                        <TableCell sx={cellStyle}>
                          {isValid(parseISO(lead.created))
                            ? format(parseISO(lead.created), "MM/dd/yyyy")
                            : "Invalid Date"}
                        </TableCell>
                        <TableCell sx={cellStyle}>{lead.full_name}</TableCell>
                        <TableCell sx={cellStyle}>
                          {lead.business_name}
                        </TableCell>
                        <TableCell sx={cellStyle}>{lead.email}</TableCell>
                        <TableCell sx={cellStyle}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            {lead.stage}
                          </div>
                        </TableCell>
                        <TableCell sx={cellStyle}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            {lead.status}
                            <Tooltip
                              key={lead.id}
                              title={getToolTipDefinitions(lead.status) || ""}
                              placement="top-start"
                            >
                              <IconButton
                                sx={{ fontSize: "0.9375rem", color: "#ccc" }}
                              >
                                <InfoIcon sx={{ fontSize: "0.9375rem" }} />
                              </IconButton>
                            </Tooltip>
                          </div>
                        </TableCell>
                        <TableCell sx={cellStyle}>
                          <IconButton
                            sx={{ fontSize: "0.9375rem", color: "#ccc" }}
                            onClick={(event) => handleIconClick(event, lead)}
                          >
                            <DescriptionIcon sx={{ fontSize: "1.25rem" }} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={7} sx={cellStyle}>
                      <Typography align="center">No Leads</Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <TablePagination
              component="div"
              count={filteredLeads?.length ?? 0}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowsPerPageOptions={[20, 50, 100]}
            />
          </TableContainer>
          <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleClosePopover}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            {/* Hidden file input */}
            <input
              type="file"
              id="file-upload-input"
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
            <Box p={1} display="flex" flexDirection="column">
              <Button
                onClick={() => handleButtonClick("application")}
                variant="text"
                color="primary"
              >
                Upload Application
              </Button>
              <Button
                onClick={() => handleButtonClick("bankstatement")}
                variant="text"
                color="primary"
              >
                Upload Bank Statement
              </Button>
            </Box>
          </Popover>
          <UploadLeadForm
            open={dialogOpen}
            onClose={closeDialog}
            onConfirm={handleUploadLead}
            loading={dialogLoading}
            progress={dialogProgress}
            message={dialogMessage}
          />
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default Dashboard;
