import React, { useState, FormEvent, CSSProperties, ChangeEvent } from "react";
import {
  Button,
  TextField,
  Container,
  Typography,
  Box,
  InputAdornment,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

interface ValidationError {
  email?: string;
  code?: string; // Add code property to ValidationError
}

interface CodeVerificationProps {
  setIsCodeVerified: React.Dispatch<React.SetStateAction<boolean>>;
}

const CodeVerification: React.FC<CodeVerificationProps> = ({
  setIsCodeVerified,
}) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>("");
  const [code, setCode] = useState<string>("");
  const [showCodeInput, setShowCodeInput] = useState<boolean>(false); // Change type to boolean
  const [codeVerified, setCodeVerified] = useState<boolean>(false);
  const [errors, setErrors] = useState<ValidationError>({});

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    let validationErrors: ValidationError = {};

    if (!email) {
      validationErrors.email = "Email is required";
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      try {
        // Send code to the email
        await axios.post(
          "https://backend.partner-bigthinkcapital.com/api/send-code",
          { email: email },
          { withCredentials: true }
        );
        setShowCodeInput(true);
        setErrors({});
      } catch (error) {
        console.error("Error sending code:", error);
      }
    }
  };

  const handleCodeChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const newCode = event.target.value;
    setCode(newCode);

    if (newCode.length === 6) {
      // Verify the code
      try {
        await axios.post(
          "https://backend.partner-bigthinkcapital.com/api/verify-code",
          {
            email: email,
            code: newCode,
          }
        );
        setCodeVerified(true);
        setErrors({});
      } catch (error) {
        console.error("Error verifying code:", error);
        setErrors({ code: "Invalid code" });
        setCodeVerified(false);
      }
    } else {
      setCodeVerified(false);
    }
  };

  const handleResendCode = async () => {
    try {
      await axios.post(
        "https://backend.partner-bigthinkcapital.com/api/send-code",
        { email: email }
      );
      setErrors({});
    } catch (error) {
      console.error("Error resending code:", error);
    }
  };

  const handleCreateNewPassword = () => {
    // Navigate to the reset password page
    setIsCodeVerified(true);
    navigate("/password-reset", { state: { email: email } });
  };

  const gradientStyle: CSSProperties = {
    background: "linear-gradient(90deg, rgb(24, 94, 157), rgb(45, 145, 195))",
  };

  return (
    <Container component="main">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img
          src="/btc_logo_transparent.png"
          alt="Logo"
          style={{ marginBottom: "2%", width: "25%", height: "25%" }}
        />
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{ mt: 1, width: "100%", maxWidth: "30%" }}
        >
          <Typography>Enter your account email:</Typography>
          <Box>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              error={!!errors.email}
              helperText={errors.email}
            />
            {showCodeInput && (
              <Box mt={2}>
                <Typography>Enter the code sent to your email:</Typography>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="code"
                  label="Verification Code"
                  name="code"
                  value={code}
                  onChange={handleCodeChange}
                  error={!!errors.code}
                  helperText={errors.code}
                  InputProps={{
                    endAdornment: codeVerified ? (
                      <InputAdornment position="end">
                        <CheckCircleIcon
                          style={{
                            color:
                              "linear-gradient(90deg, rgb(24, 94, 157), rgb(45, 145, 195))",
                          }}
                        />
                      </InputAdornment>
                    ) : null,
                  }}
                />
                <Button onClick={handleResendCode}>Resend Code</Button>
                {codeVerified && (
                  <Button
                    fullWidth
                    variant="contained"
                    style={gradientStyle}
                    sx={{ mt: 3, mb: 2 }}
                    endIcon={<ArrowForwardIcon />}
                    onClick={handleCreateNewPassword}
                  >
                    Create New Password
                  </Button>
                )}
              </Box>
            )}
            {Object.keys(errors).map((key) => (
              <Typography key={key} color="#B30000">
                {errors[key as keyof ValidationError]}
              </Typography>
            ))}
            {!showCodeInput && (
              <Button
                type="submit"
                fullWidth
                variant="contained"
                style={gradientStyle}
                sx={{ mt: 3, mb: 2 }}
              >
                Send Code
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default CodeVerification;
