import React, { useState, useEffect } from "react";
import LoginForm from "./components/loginForm";
import Home from "./components/home";
import CodeVerification from "./components/codeVerification";
import PasswordReset from "./components/passwordReset";
import UsersDirectory from "./components/usersDirectory";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { CssBaseline, ThemeProvider, createTheme } from "@mui/material";
import PrivateRoute from "./components/PrivateRoute";
import AddUserForm from "./components/addUserForm";
import ContactUs from "./components/contactUs";
import axios from "axios";

const theme = createTheme();

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [isImposter, setIsImposter] = useState<boolean>(false);
  const [role, setRole] = useState<"admin" | "super-admin" | "user" | null>(
    null
  );
  const [isCodeVerified, setIsCodeVerified] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    // const checkLoginStatus = async () => {
    //   try {
    //     const response = await axios.get('https://backend.partner-bigthinkcapital.com/api/get-loggedIn-status', { withCredentials: true });
    //     if (response.data === true) {
    //       const resp = await axios.get('https://backend.partner-bigthinkcapital.com/api/get-user-role', { withCredentials: true });
    //       console.log('response data: ', resp.data);
    //       setRole(resp.data);
    //       setIsLoggedIn(true);
    //     }
    //   } catch (error) {
    //     console.error('Error checking login status:', error);
    //   } finally {
    //     setLoading(false); // Ensure loading is set to false after check
    //   }
    // };
    // checkLoginStatus();
  }, []);

  // if (loading) {
  //   return <div>Loading...</div>; // Show a loading indicator while checking login status
  // }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Routes>
          {/* <Route path="/" element={<PrivateRoute isLoggedIn={isLoggedIn} userRole={role}  element={<LoginForm setIsLoggedIn={setIsLoggedIn} setIsImposter={setIsImposter} />}/>}/> */}
          <Route
            path="/"
            element={
              <LoginForm
                setIsLoggedIn={setIsLoggedIn}
                setIsImposter={setIsImposter}
              />
            }
          />
          <Route
            path="/home"
            element={
              <PrivateRoute
                isLoggedIn={isLoggedIn}
                element={
                  <Home role={role} setRole={setRole} isImposter={isImposter} />
                }
              />
            }
          />
          <Route
            path="/contact-us"
            element={
              <PrivateRoute isLoggedIn={isLoggedIn} element={<ContactUs />} />
            }
          />
          <Route
            path="/code-verification"
            element={<CodeVerification setIsCodeVerified={setIsCodeVerified} />}
          />
          <Route
            path="/password-reset"
            element={
              <PrivateRoute
                isLoggedIn={isLoggedIn}
                isCodeVerified={isCodeVerified}
                element={
                  <PasswordReset setIsCodeVerified={setIsCodeVerified} />
                }
              />
            }
          />
          <Route
            path="/users-directory"
            element={
              <PrivateRoute
                isLoggedIn={isLoggedIn}
                requiredRole="admin"
                userRole={role}
                // isImposter={isImposter}
                element={
                  <UsersDirectory
                    role={role}
                    setRole={setRole}
                    isImposter={isImposter}
                    setIsImposter={setIsImposter}
                  />
                }
              />
            }
          />
          <Route
            path="/create-user"
            element={
              <PrivateRoute
                isLoggedIn={isLoggedIn}
                userRole={role}
                requiredRole="admin"
                element={<AddUserForm role={role} />}
              />
            }
          />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
